import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-policies-pt',
  templateUrl: './policies-pt.component.html',
  styleUrls: ['./policies-pt.component.css']
})
export class PoliciesPtComponent implements OnInit {

  public version: string|null = environment.appVersion;
  constructor() { }

  ngOnInit(): void {
  }

}
