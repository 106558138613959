<mat-card class="mat-elevation-z8" *ngIf="!success">
	<mat-card-header>
		<mat-card-title>{{'password-reset'|translate}}</mat-card-title>
        <mat-card-subtitle>{{'create-new-password'|translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
    	<form id="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    		<mat-form-field>
    			<mat-label>{{'new-password'|translate}}</mat-label>
    			<input matInput type="password" autocomplete="off" formControlName="password" required="required">
    			<mat-error>{{'password-validation'|translate}}</mat-error>
    		</mat-form-field>
    		<mat-form-field>
    			<mat-label>{{'confirm'|translate}}</mat-label>
    			<input matInput type="password" autocomplete="off" formControlName="password_confirmation" required="required">
    			<mat-error *ngIf="hasConfirmationError()">{{'passwords-dont-match'|translate}}</mat-error>
    		</mat-form-field>
    	</form>
    </mat-card-content>
    <mat-card-actions class="dark-theme">
    	<button type="submit" form="form" mat-raised-button color="primary">
    		<mat-icon>save</mat-icon> {{'save'|translate}}
    	</button>
    </mat-card-actions>
</mat-card>
<mat-card class="mat-elevation-z8" *ngIf="success">
	<mat-card-header>
		<mat-card-title>{{'password-changed'|translate}}</mat-card-title>
        <mat-card-subtitle>{{'password-changed-info'|translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
    	{{'password-changed-message1'|translate}}<a routerLink="/login">{{'password-changed-message2'|translate}}</a> {{'password-changed-message3'|translate}}
    </mat-card-content>
</mat-card>