<div class="mat-elevation-z8">
	<mat-card class="example-card">
	  <mat-card-header>
	    <mat-card-title>{{ test?.title }}</mat-card-title>
	    <mat-card-subtitle>
	    	{{'published-by'|translate}} @{{ test?.teacher.username }}, {{ test?.teacher.name }} {{ test?.teacher.surname }} {{'at'|translate}} {{ test?.created_at | date:'MMM d, y, HH:mm' }}
	    </mat-card-subtitle>
	  </mat-card-header>
	  <mat-card-content>
        <p class="test-description">
            <span>{{'max-attempts'|translate}}: {{ test?.max_allowed_attempts }} ({{ test?.max_allowed_attempts - test?.attempts_count }} {{ 'left'|translate|lowercase}})</span>
		</p>
		<p class="test-description">
            <span>{{'allow-reviewing'|translate}}: {{ test?.allow_review==0 }}</span>
		</p>
		<p class="test-description">
            <span>{{'number-of-questions'|translate}} {{ test?.no_questions }}</span>
		</p>
		<p class="test-description">
			<span *ngIf="test?.time_limit"><b>{{'time-limit'|translate}}: {{ test?.time_limit }} {{'minutes'|translate|lowercase}}</b></span>
			<span *ngIf="!test?.time_limit">{{'time-limit'|translate}}: {{'unlimited'|translate}}</span>
        </p>
	  	<div class="vivid-theme" *ngIf="!testStarted">
			<div *ngIf="test?.allow_start">
				<p>{{'before-start-warning'|translate}}</p>
				<button type="button" mat-raised-button color="primary" (click)="start()"><mat-icon>play_arrow</mat-icon> {{'start-test'|translate}}</button>
			</div>
			<div *ngIf="!test?.allow_start">
				<p>{{'cant-start-private-test'|translate}}</p>
			</div>
	  	</div>
		<div *ngIf="testStarted">
			<p>{{'test-tip'|translate}}</p>
		</div>
	  </mat-card-content>
	</mat-card>
</div>
<mat-card class="mat-elevation-z8 card-lesson" *ngIf="test?.time_limit && testStarted && !testCompleted">
	<mat-card-header>
		<mat-card-title>
			<span *ngIf="timeLeft>0">{{timeLeftHour}}h {{ timeLeftMin }}m {{ timeLeftSec }}s {{ 'left'|translate|lowercase}}</span>
			<span *ngIf="timeLeft<=0">{{'test-timed-out'|translate}}</span>
		</mat-card-title>
		<mat-card-subtitle>
			{{ 'ending'|translate }}: {{ dateStart+test?.time_limit*60000 | date:'MMM d, y, HH:mm:ss' }}
		</mat-card-subtitle>
	</mat-card-header>
</mat-card>
<mat-card class="mat-elevation-z8 card-lesson" *ngIf="test?.lessons?.length && !testStarted">
	<mat-card-header>
		<mat-card-title>{{'tests-lessons'|translate}}</mat-card-title>
		<mat-card-subtitle>{{'tests-lessons-message'|translate}}</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<div *ngFor="let lesson of test?.lessons">
			<h3>
				<a [routerLink]="'/student/lesson/'+lesson.uuid" *ngIf="lesson.privacy==1 || test?.are_friends">{{lesson.title}}</a>
				<span *ngIf="lesson.privacy==2 && !test?.are_friends">{{lesson.title}}</span>
			</h3>
		</div>
	</mat-card-content>
</mat-card>

<mat-spinner *ngIf="isLoadingResults" [diameter]="50"></mat-spinner>

<div class="mat-elevation-z8" *ngIf="testStarted&&!testCompleted&&!isLoadingResults">
	<mat-card>
		<mat-card-header>
			<mat-card-title>
				{{ question?.question }}
			</mat-card-title>
			<mat-card-subtitle [ngSwitch]="question?.type">
				<span>{{'question'|translate}} {{ question.item_order }} {{'of'|translate}} {{ test?.no_questions }}: </span>
				<span *ngSwitchCase="1">{{'essay'|translate}}</span>
				<span *ngSwitchCase="2">{{'short-answer'|translate}}</span>
				<span *ngSwitchCase="3">{{'short-answer'|translate}}</span>
				<span *ngSwitchCase="4">{{'short-answer'|translate}}</span>
				<span *ngSwitchCase="5">{{'select-one'|translate}}</span>
				<span *ngSwitchCase="6">{{'select-all-true'|translate}}</span>
			</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<form id="answer" [formGroup]="answerForm" (ngSubmit)="nextAnswer()">
				<div *ngIf="question?.type==1" class="form-field">
					<mat-form-field class="full-width-input">
    					<textarea matInput placeholder="Write your answer:" [formControlName]="question.uuid" rows="20"></textarea>
						<mat-hint [align]="start">{{'essay-click-next'|translate}}</mat-hint>    					
    				</mat-form-field>
				</div>
				<div *ngIf="question?.type>=2 && question.type<=4" class="form-field">
					<mat-form-field class="full-width-input">
    					<input matInput placeholder="Answer:" [formControlName]="question.uuid" autocomplete="off">
						<mat-hint [align]="start">{{'short-answer-click-next'|translate}}</mat-hint>
    				</mat-form-field>
				</div>
				<div *ngIf="question?.type==5">
					<mat-radio-group [formControlName]="question.uuid">
						<mat-radio-button *ngFor="let option of question?.options" [value]="option.uuid">
							{{ option.answer }}
						</mat-radio-button>
					</mat-radio-group>
				</div>
				<div *ngIf="question?.type==6" class="check-boxes">
					<mat-checkbox *ngFor="let option of question?.options" [formControlName]="option.uuid">{{ option.answer }}</mat-checkbox>					
				</div>
			</form>
		</mat-card-content>
		<mat-card-actions align="end" class="vivid-theme">
			<button type="submit" form="answer" mat-raised-button color="primary">
				<mat-icon>navigate_next</mat-icon> {{'next'|translate}}
			</button>
		</mat-card-actions>
	</mat-card>
</div>

<div class="mat-elevation-z8" *ngIf="testCompleted">
	<mat-card>
		<mat-card-header>
			<mat-card-title>
				{{'congratulations'|translate}}!
			</mat-card-title>
			<mat-card-subtitle>
				{{'test-completed-info'|translate}}
			</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<p>{{'test-completed-message1'|translate}}</p>
			<p>{{'test-completed-message2'|translate}}</p>
			<p>{{'test-completed-message3'|translate}}</p>
		</mat-card-content>
	</mat-card>
</div>