<app-table 	[displayedColumns]="displayedColumns" 
			[columns]="columns" 
			[pageSize]="25" 
			url="/teachers/grading"
			[title]="'grade-tests'|translate"
			sortColumn="completed_at"
			sortDirection="desc"
			formLink='/teacher/grading/'
			editParam='uuid'
            hideAddButton="true">
</app-table>