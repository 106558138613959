<div class="mat-elevation-z8">
  <div class="mat-h1">
    <span >{{ title }}</span>
  </div>
  <div class="action-search">
    <a mat-raised-button matTooltip="Create a new element" color="accent" [routerLink]="formLink+'create'" *ngIf="!hideAddButton">
        <mat-icon>add</mat-icon> {{'add-new'|translate}}
    </a>
    <span class="fill-space"></span>
    <mat-form-field [style.width.px]=220 *ngIf="!hideSearch">
      <mat-label><mat-icon matSuffix>search</mat-icon> {{'search'|translate}}</mat-label>
      <input type="tel" matInput placeholder="Type at least 3 characters" autocomplete="off" (input)="search()" [(ngModel)]="searchVal">
      <button mat-button *ngIf="searchVal" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <mat-spinner *ngIf="isLoadingResults" [diameter]="50"></mat-spinner>
  <table mat-table [dataSource]="dataSource" matSort [matSortActive]="sortColumn" matSortDisableClear [matSortDirection]="sortDirection" style="width: 100%;">
    <ng-container *ngFor="let col of columns" [matColumnDef]="col.title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ col.caption | translate }} </th>
      <td mat-cell *matCellDef="let row" [style.width]="col.width">
        <a [routerLink]="formLink+row[editParam]" *ngIf="formLink!=''">{{ genRowValue(row[col.title], col.filter) }}</a>
        <span *ngIf="formLink==''">{{ genRowValue(row[col.title], col.filter) }}</span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="resultsLength" [pageSize]="pageSize"></mat-paginator>
</div>