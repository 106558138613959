import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-account-recovery',
  templateUrl: './account-recovery.component.html',
  styleUrls: ['./account-recovery.component.css']
})
export class AccountRecoveryComponent implements OnInit {

	success: boolean = false;
	error: boolean = false;

	form = new UntypedFormGroup({
			email: new UntypedFormControl('', [Validators.required, Validators.email])
		});

	constructor(private http: HttpClient) { }

  	ngOnInit(): void {
  	}

  	onSubmit(){
  		if (!this.form.valid)
  			return;

  		this.http.post(`${environment.apiURL}/account-recovery`, this.form.getRawValue())
  			.subscribe({
				next: (result:any)=>{
  					this.success = true;
  					this.error = false;
  				}, error: error=>{
  					this.success = false;
  					this.error = true;
  				}
			});
  	}

}
