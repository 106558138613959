<h2 mat-dialog-title>{{'build-question'|translate}}</h2>
<mat-spinner *ngIf="isLoading" [diameter]="50"></mat-spinner>
<mat-dialog-content class="mat-typography" *ngIf="!isLoading">
  <h3>{{'build-question-tip'|translate}}</h3>
  <form [formGroup]="questionForm" id="modalForm" (ngSubmit)="saveQuestion()">
    <mat-form-field>
      <mat-label>{{'question'|translate}}</mat-label>
      <input matInput formControlName="question" [placeholder]="'ask-here'|translate" autocomplete="off">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'weight'|translate}}</mat-label>
      <input matInput formControlName="weight" [placeholder]="'weight-placeholder'|translate" autocomplete="off" type="number" min="0" step="1">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'type'|translate}}</mat-label>
      <mat-select formControlName="type">
        <mat-option [value]=1>{{'essay'|translate}}</mat-option>
        <mat-option [value]=2>{{'open-short-answer'|translate}}</mat-option>
        <mat-option [value]=3>{{'strict-short-answer'|translate}}</mat-option>
        <mat-option [value]=4>{{'options-short-answer'|translate}}</mat-option>
        <mat-option [value]=5>{{'multiple-choice'|translate}}</mat-option>
        <mat-option [value]=6>{{'true-or-false'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="input-hint" *ngIf="questionForm.controls.type.value==1">
      {{'essay-description'|translate}}
    </div>
    <div class="input-hint" *ngIf="questionForm.controls.type.value==2">
      {{'open-short-answer-description'|translate}}
    </div>
    <div class="input-hint" *ngIf="questionForm.controls.type.value==3">
      {{'strict-short-answer-description'|translate}}
    </div>
    <div class="input-hint" *ngIf="questionForm.controls.type.value==4">
      {{'options-short-answer-description'|translate}}
    </div>
    <div class="input-hint" *ngIf="questionForm.controls.type.value==5">
      {{'multiple-choice-description'|translate}}
    </div>
    <div class="input-hint" *ngIf="questionForm.controls.type.value==6">
      {{'true-or-false-description'|translate}}
    </div>
    <mat-form-field *ngIf="(questionForm.controls.type.value==2||questionForm.controls.type.value==3)">
      <mat-label>{{'answer'|translate}}</mat-label>
      <input matInput formControlName="answer" [placeholder]="'answer-placeholder'" autocomplete="off">
    </mat-form-field>
    <div cdkDropList (cdkDropListDropped)="updateOptionsSorting($event)" formArrayName="options" *ngIf="questionForm.controls.type.value>3">
      <div *ngFor="let option of optionsFormArray.controls; let i = index;" cdkDrag>
        <mat-grid-list cols="12" [formGroupName]="i" class="container" rowHeight="70px">
          <div class="question-drag-placeholder" *cdkDragPlaceholder></div>
          <mat-grid-tile [colspan]="questionForm.controls.type.value>4 ? 6 : 10">
            <mat-form-field>
              <mat-label>Answer</mat-label>
              <input matInput formControlName="answer" [placeholder]="'answer'|translate" autocomplete="off">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="4" *ngIf="questionForm.controls.type.value>4">
            <mat-slide-toggle formControlName="is_true" class="example-margin" [color]="primary">
              {{'is-correct'|translate}}
            </mat-slide-toggle>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-icon (click)="removeOption(i)" class="btn-trash">delete</mat-icon>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="drag-handle" cdkDragHandle>
              <mat-icon>open_with</mat-icon>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <div class="vivid-theme btn-add">
        <button  mat-raised-button color="primary" type="button" (click)="addOption()">
          {{'add-option'|translate}}
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button type="button" color="accent" mat-dialog-close>{{'cancel'|translate}}</button>
  <div class="dark-theme">
    <mat-spinner *ngIf="isSaving" [diameter]="30"></mat-spinner>
    <button mat-raised-button type="submit" form="modalForm" color="primary" cdkFocusInitial *ngIf="!isSaving">
      {{'save-question'|translate}}
    </button>
  </div>
</mat-dialog-actions>