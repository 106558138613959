import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  columns = [	{title: 'message', caption: 'message', filter: 'none', width: '100%'}
  			  ];
  displayedColumns = ['message'];

  constructor() { }

  ngOnInit(): void {
  }

}
