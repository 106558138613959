<h1 mat-dialog-title color="accent">
  <mat-icon>dangerous</mat-icon> {{title}}
</h1>
<div mat-dialog-content>
  <p>{{message}}</p>
</div>
<div mat-dialog-actions class="dark-theme">
  <button mat-raised-button (click)="onClose(false)" color="accent">No</button>
  <span class="fill-space"></span>
  <button mat-raised-button color="primary" (click)="onClose(true)">Yes</button>
</div>