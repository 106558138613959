<mat-card class="mat-elevation-z">
  <mat-card-content class="login-card">
    <mat-grid-list [cols]="breakpoint" rowHeight="50" (window:resize)="onResize($event)">
      <mat-grid-tile [rowspan]="breakpoint==1 ? 3 : 8">
        <div class="banner">
          <h1 [ngClass]="{'mg25': breakpoint!=1, 'mg2': breakpoint==1}">Ddolphin</h1>
          <h2>{{ 'slogan1' | translate }}<br />{{ 'slogan2' | translate }}</h2>
        </div>
      </mat-grid-tile>
      <mat-grid-tile rowspan="5">
        <div class="login-form">
          <form id="form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <img src="assets/images/logo/logo.svg" height="64">
            <h2 class="login-title">Log In</h2>
            <mat-form-field class="full-width-input" color="accent">
              <mat-label>{{ 'email-input' | translate }}</mat-label>
              <input matInput autocomplete="email" [placeholder]="'email-input' | translate" formControlName="email" required>
              <mat-error>
                {{ 'empty-email' | translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
              <mat-label>{{ 'password'| translate }}</mat-label>
              <input matInput autocomplete="current-password" type="password" [placeholder]="'password'| translate" formControlName="password" required>
              <mat-error>
                {{ 'emtpy-password' | translate}}
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <span class="btn vivid-theme">
          <button type="submit" form="form" mat-raised-button color="primary">Login</button>
        </span>
        <span class="btn vivid-theme">
          <button type="button" mat-raised-button color="warn" routerLink="/account-recovery">{{'recovery' | translate}}</button>
        </span>
      </mat-grid-tile>
      <mat-grid-tile>
        <button type="button" class="google-button" mat-raised-button color="primary" (click)="googleAuth()">
          <mat-icon svgIcon="google"></mat-icon>
          {{'google-signin' | translate}}
        </button>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="policies">
          <a [href]="getPoliciesLink(true)">{{'cookies-link' | translate}}</a>
          <a [href]="getPoliciesLink(false)">{{'policy-link' | translate}}</a>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>