import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-student-test-review',
  templateUrl: './student-test-review.component.html',
  styleUrls: ['./student-test-review.component.css']
})
export class StudentTestReviewComponent implements OnInit {
  public questions: any;
  public attempt: any;
  public isLoadingResults: boolean = false;
  private uuid: string|null = '';

  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.http.get(`${environment.apiURL}/students/tests/attempts/${this.uuid}`).subscribe((response:any)=>{
      this.attempt = response['data'];
      this.questions = this.attempt.questions;
      this.isLoadingResults = false;
    });
  }

}
