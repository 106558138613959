import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export class SignupValidator {

    static emailTrimValidator = function (control: AbstractControl): ValidationErrors | null {
        let value: string = control.value.trim() || '';

        if (!value)
            return null;

        let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (emailPattern.test(value)===false)
            return { emailValidation: 'Invalid e-mail' };

        return null;
    }

    static passwordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

        let value: string = control.value || '';

        if (!value)
            return null

        let strongPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?!,.;:+-\/*@$%&#\[\]\(\)<>\\_|]).{8,}$/g;
        if (strongPassword.test(value) === false)
            return { passwordStrength: 'Password is not strong enough!' };

        return null;
    }

    static usernameFormatValidator = function (control: AbstractControl): ValidationErrors | null {

        let value: string = control.value || '';

        if (!value)
            return null

        let usernameFormat = /^([a-z0-9_]){3,}$/g;
        if (usernameFormat.test(value) === false)
            return { usernameFormat: 'Username must contain only letters, numbers, full stop, underscore or hyphen' };

        return null;
    }

    static passwordsMatch = function (): any {
        return (formGroup: UntypedFormGroup) => {
            let password = formGroup.controls['password'];
            let passConf = formGroup.controls['password_confirmation'];

            if (password.errors && !passConf.errors)
                return;

            passConf.setErrors(password.value == passConf.value ? null : { confirmation: true });

            return null;
        }
    }
}