import { AbstractControl, ValidationErrors } from '@angular/forms';

export class YoutubeValidator{

    static urlValidator = function (control: AbstractControl): ValidationErrors | null {

        let value: string = control.value || '';
    
        if (!value)
            return null
    
        let regex = /(?:https?:\/\/)?(?:www\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/shorts\/|\/)(\w+)/;
        if (regex.exec(value)===null)
            return { url: "URL doesn't seem to be a Youtube URL" };
    
        return null;
    }
}