import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Capacitor } from '@capacitor/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { MessagingService } from './messaging-service';
import { PushAndroidService } from './push-android.service';
import { TranslateService } from '@ngx-translate/core';

const platform = Capacitor.getPlatform();

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	public user: any;
	public isAuthenticated: boolean = false;

	constructor(private router: Router, private http: HttpClient, private toastr: ToastrService,
		private fireMessaging: AngularFireMessaging, private msgService: MessagingService, private androidPush: PushAndroidService,
		private translate: TranslateService) {
		if (platform == 'web')
			GoogleAuth.initialize();
	}

	async signToken() {
		//if the server resonds with the token, then sets the user as authenticated
		//this GET will be intercepted and the token will be passed as a header
		this.http.get(environment.apiURL + '/user', { responseType: 'json' })
			.subscribe({
				next: (response: any) => {
					this.user = response['data'];
					this.isAuthenticated = true;
				},
				error: err => {
					this.user = null;
					this.isAuthenticated = false;
					localStorage.removeItem("auth-token");
					this.router.navigate(['login']);
				}
			});
	}

	async login(email: string, password: string) {
		await this.http.post(environment.apiURL + '/login', { email: email, password: password }, { responseType: 'json' })
			.subscribe({
				next: (response: any) => {
					this.completeLogin(response['data']);
				},
				error: err => {
					if (err.status == 401)
						this.toastr.error(this.translate.instant('invalid-login'), this.translate.instant('login-fail'));
					else
						this.toastr.error(this.translate.instant('http.error'), `${this.translate.instant('error')}: ${err.status}`);
				}
			});
	}

	async googleAuth() {
		console.log('bla');
		let user = await GoogleAuth.signIn();
		await this.http.post(`${environment.apiURL}/login/oauth`, { user: user }).subscribe({
			next: (response: any) => {
				this.completeLogin(response['data']);
			},
			error: err => {
				this.toastr.error(this.translate.instant('google-fail'), `${this.translate.instant('error')}!`);
			}
		});
	}

	completeLogin(token: string) {
		localStorage.setItem('auth-token', token);
		this.isAuthenticated = true;
		this.router.navigate(['/']);
		if (platform == 'web')
			this.msgService.requestPermission();
		else if (platform=='android')
			this.androidPush.postToken();
	}

	async logout(navigateToLogin: boolean) {
		let token: string|null = '';
		//could use ternary for now, but with the implementation of iOS will need to come back to if/else or switch anyways
		if (platform == 'web')
			token = this.msgService.getToken();
		else if (platform=='android')
			token = this.androidPush.getToken();

		await this.http.post(`${environment.apiURL}/logout`, { token: token }).subscribe((response: any) => {
			localStorage.removeItem('auth-token');
			this.isAuthenticated = false;
			if (navigateToLogin)
				this.router.navigate(['login']);
		});
	}
}
