<cdk-virtual-scroll-viewport [itemSize]="72" class="content">
  <mat-card class="mat-elevation-z8 card-lesson" *ngIf="!items.length && !loading">
    {{ 'empty-home-message'|translate}}
  </mat-card>
  <mat-card class="mat-elevation-z8 card-lesson" *cdkVirtualFor="let item of items">
    <a  routerLink="{{ item.type=='lesson' ? '/student/lesson/'+item.uuid : '/student/test/'+item.uuid }}">
      <mat-card-header>
        <div [ngClass]="{'tests-icon': item.type=='test', 'lessons-icon': item.type=='lesson'}">
          <mat-icon>{{ item.type=='lesson' ? 'topic' : 'history_edu'}}</mat-icon>
        </div>
        <span class="title">
          <mat-card-title>
              {{ item.title }}
          </mat-card-title>
          <mat-card-subtitle>{{'publisehd-by'|translate}}{{ item.name }} {{ item.surname }} {{'at'|translate}} {{ item.created_at | date:'MMM d, y, HH:mm'}} </mat-card-subtitle>
        </span>
      </mat-card-header>
      <mat-card-content [innerHtml]="item.description.substring(0, 250)" *ngIf="item.type=='lesson'"></mat-card-content>
      <mat-card-content *ngIf="item.type=='test'">
        <p class="test-description">
          {{ 'attempts'|translate }}: {{ item.max_allowed_attempts }}
        </p>
        <p class="test-description">
          {{ 'allow-reviewing' | translate}}: {{ item.description==1 }}
        </p>
        <p class="test-description">
          {{ 'time-limit'|translate }}: 
          <span *ngIf="item.time_limit">{{ item.time_limit }} {{ 'minutes'|translate|lowercase }}</span>
          <span *ngIf="!item.time_limit">{{ 'unlimited'|translate }}</span>
        </p>
        <p class="test-description">
          
        </p>
        <p class="test-description">
          
        </p>
      </mat-card-content>
    </a>
  </mat-card>
  <div class="spinner" *ngIf="loading">
    <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
  </div>
</cdk-virtual-scroll-viewport>