import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { map, startWith } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.css']
})
export class ChipsComponent implements OnInit{

	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input() getUrl: string = '';
	@Input() postUrl: string = '';
	@Input() attachMessage: string = '';
	@Input() detachMessage: string = '';
	
	visible = true;
	selectable = true;
	removable = true;
	
	searchCtrl = new UntypedFormControl();
	filteredOptions = new Observable<string[]>();
	selectedOptions: Object[] = [];
	allOptions: string[] = [];

	@ViewChild('chipInput') chipInput: any = ElementRef<HTMLInputElement>;
	@ViewChild('auto') matAutocomplete: any = MatAutocomplete;

	constructor(private http: HttpClient, private toastr: ToastrService, private translate: TranslateService) {}

	ngOnInit(): void {
		this.http.get(`${environment.apiURL}/${this.getUrl}`).subscribe((response: any)=>{
			let selected = response.data;
			selected.forEach((item: any) => {
				this.selectedOptions.push({label: item.label, value: item.value});
			});
		});
	}

	remove(option: any): void {
		this.http.delete(`${environment.apiURL}/${this.postUrl}/${option['value']}`)
			.subscribe((response: any)=>{
				const index = this.selectedOptions.indexOf(option);

	    		if (index >= 0)
	      			this.selectedOptions.splice(index, 1);

				this.toastr.success(this.translate.instant(this.detachMessage), `${this.translate.instant('success')}!`);
			});
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.http.post(`${environment.apiURL}/${this.postUrl}`, {option_value: event.option.value})
			.subscribe(response=>{
				this.selectedOptions.push({label: event.option.viewValue, value: event.option.value});
			    this.chipInput.nativeElement.value = '';
			    this.searchCtrl.reset();

				this.toastr.success(this.translate.instant(this.attachMessage), `${this.translate.instant('success')}!`);
			});
	}

	private _filter(value: string): string[] {
	    const filterValue = value.toLowerCase();

	    return this.allOptions.filter((option: any) => option['label'].toLowerCase().indexOf(filterValue) === 0);
	}

	search(){
		const searchVal = this.searchCtrl.value.trim();
		//only searches if typed 3 or more letters...
		if (searchVal.length>2)
	  		this.http.get(`${environment.apiURL}/${this.getUrl}/${searchVal}`)
	  			.subscribe((response: any)=>{
	  				this.allOptions = response.data;
	  				this.filteredOptions = this.searchCtrl.valueChanges
	  					.pipe(
        					startWith(null),
        					map((option: string | null) => option ? this._filter(option) : this.allOptions.slice())
        				);
	  			});
	}

}

export class Options {
	label: string = '';
	value: string = '';
}