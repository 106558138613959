<app-form   [items]="formItems"
            [options]="formOptions">  
</app-form>
<div class="mat-elevation-z8" *ngIf="uuid && !isLoading">
	<mat-card class="example-card">
	  <mat-card-header>
	    <mat-card-title>{{'questions'|translate}}</mat-card-title>
	    <mat-card-subtitle>
	    	{{'questions-description'|translate}}
	    </mat-card-subtitle>
	  </mat-card-header>
	  <mat-card-content>
	  	<div class="vivid-theme">
	  		<button type="button" mat-raised-button color="primary" (click)="addQuestion()"><mat-icon>add</mat-icon> Add Question</button>
	  	</div>
	  </mat-card-content>
	</mat-card>
	<div cdkDropList id="questionsDropList" (cdkDropListDropped)="updateSorting($event)">
		<mat-card *ngFor="let question of questions; index as i;" cdkDrag>
			<div class="question-drag-placeholder" *cdkDragPlaceholder></div>
			<mat-card-header (click)="editQuestion(question.uuid)">
				<mat-card-title>
					{{ question.question }}
				</mat-card-title>
				<mat-card-subtitle [ngSwitch]="question.type">
					<p *ngSwitchCase="1">{{'essay'|translate}}</p>
					<p *ngSwitchCase="2">{{'open-short-answer'|translate}}</p>
					<p *ngSwitchCase="3">{{'strict-short-answer'|translate}}</p>
					<p *ngSwitchCase="4">{{'options-short-answer'|translate}}</p>
					<p *ngSwitchCase="5">{{'multiple-choice'|translate}}</p>
					<p *ngSwitchCase="6">{{'true-or-false'|translate}}</p>
				</mat-card-subtitle>
			</mat-card-header>
			<mat-card-content (click)="editQuestion(question.uuid)">
				<h4 [ngClass]="{'is-true': option.is_true}" *ngFor="let option of question.options">
					{{ option.answer }}
				</h4>
				<h4 *ngIf="question.type==2 || question.type==3">
					{{ question.answer }}
				</h4>
			</mat-card-content>
			<mat-card-actions class="flex">
				<button type="button" mat-raised-button color="warn" (click)="removeQuestion(question.uuid)">
					<mat-icon>delete</mat-icon> {{'remove'|translate}}
				</button>
				<div class="drag-handle" cdkDragHandle>
					<mat-icon>open_with</mat-icon>
				</div>
			</mat-card-actions>
		</mat-card>
	</div>
</div>