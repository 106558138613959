import { ConfirmationDialogModel, ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';

import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FriendshipService {

  constructor(private http: HttpClient, private dialog: MatDialog, private translate: TranslateService) { }

  confirmUnfollowing() {
    const dialogData = new ConfirmationDialogModel(this.translate.instant('confirm-action'), this.translate.instant('confirm-unfollow'));
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: "70%",
      data: dialogData,
      backdropClass: 'backdrop-background',
      hasBackdrop: true
    });
    dialogRef.updatePosition({ top: "10%" });

    return dialogRef.afterClosed();
  }

  unfollow(uuid: string) {
    return this.http.delete(`${environment.apiURL}/friendship/${uuid}`);
  }

  follow(uuid: string) {
    return this.http.post(`${environment.apiURL}/friendship`, { friend_uuid: uuid });
  }

  block(uuid: string) {
    return this.http.patch(`${environment.apiURL}/friendship/block`, {friend_uuid: uuid});
  }

  unblock(uuid: string) {
    return this.http.patch(`${environment.apiURL}/friendship/unblock`, {friend_uuid: uuid});
  }

  confirmBlocking() {
    const dialogData = new ConfirmationDialogModel(this.translate.instant('confirm-action'), this.translate.instant('confirm-block'));
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: "70%",
      data: dialogData,
      backdropClass: 'backdrop-background',
      hasBackdrop: true
    });
    dialogRef.updatePosition({ top: "10%" });

    return dialogRef.afterClosed();
  }
}
