<div class="header">
    <h1>Ddolphin Education</h1>
    <h2>Anyone knows something they can teach. Everyone has something to learn.</h2>
</div>
<div class="body">
    <h2>What is Ddolphin?</h2>
    <p>Ddolphin is a simple yet very powerful platform that allows users to share their knowledge with other users
        around the world.</p>
    <p>
        <span>For those teaching they can easily create lessons with an intuitive text editor in which they can embed
            images and videos. </span>
        <span>Additionally teachers can create intuitive tests so the students can practise what they've learned. These
            tests can stay online or can be printed if necessary. </span>
        <span>Tests builder allow teachers to select different types of questions and depending on the type chosen the
            system can automatically grade the tests. Of course, teachers can always change the grading if they want
            to.</span>
    </p>
    <p>
        <span>Students constantly get the newest posted tests and lessons from the people they follow so they won't miss
            out. </span>
        <span>Depending on the settings set by the teachers students are able to immediately review their completed
            tests and get instant grading.</span>
    </p>
    <p>
        Intuitive reports help both teachers to understand their students' performances and also students to see how
        well they are performing.
    </p>
    <h2>Is it free?</h2>
    <p>Yes! There's no charge either for teachers or students. In fact, both are treated equally and the same user can
        teach and learn with the same account.</p>
    <p>In addition to being free, we <b>never</b> share, sell or do whatever other dodgy scheme big tech companies do
        with your information. 😉</p>
    <h2>Is it still under development?</h2>
    <p>Yes, we're always improving and adding more features to the platform. If you have suggestions of new features or want to report a bug, feel free to reach
        out to our tem on <a href="mailto:contact@ddolphin.com">contact@ddolphin.com</a></p>
    <p>But it doesn't mean that Ddolphin isn't safe for use: you can use it as everything that was published so far was
        properly tested and is functional.</p>
    <p>Further development will introduce new tools that will make Ddolphin even more powerful!</p>
    <p>
        <span>After the launching of the initial version, the development will be continuous. Meaning that new features
            and bugs will be always added to ensure best user experience.</span>
        <span>If you have suggestions or reports please don't hesitate in contacting us with your request!</span>
    </p>
    <h2>Can I get it in my language?</h2>
    <p>Not yet. We understand that English is the language primarily used by the science community and for that reason it
        was chosen as the first language for Ddolphin.</p>
    <p>The Portuguese language was added at the end of 2022 and by the first half of 2023 Ddolphin will be translated to Spanish and then we'll take users' suggestions to
        choose the next languages.</p>
    <p>Many modern browsers allow instant translation of pages which are totally compatible with Ddolphin.</p>
    <h2>Is There an iOS App?</h2>
    <p>No and we don't plan on developing one. Basically we are a not for profit platform that focus on spreading education and yet we'd have to pay US$299 a year for Apple in order to publish an app on the App Store.</p>
    <p>We're really sorry for our Apple users but for the moment you'll have to use the web version on your browser.</p>
    <p>You can add Ddolphin to your home screen so you have a direct link to it.  😃</p>
    <h2>What are your T&C's?</h2>
    <p>Please visit our <a href="/policies" target="_blank">policies</a> page to learn more about the T&C and the
        cookies policies.</p>
</div>
<footer>
    <div>
        <img src="/assets/images/logo/icon.svg" height="36px">
        Ddolphin© current version {{ version }}
    </div>
    <span class="footer-right">
        <a href="/policies">T&C and Privacy Policies</a>
    </span>
</footer>