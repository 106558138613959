<div class="mat-elevation-z8">
	<mat-card class="example-card">
	  <mat-card-header>
	    <mat-card-title>{{'tests-marks'|translate}}</mat-card-title>
        <mat-card-subtitle>{{'tests-marks-description'|translate}}</mat-card-subtitle>
	  </mat-card-header>
	  <mat-card-content>
        <div class="line-chart" *ngIf="showTestMarks">
            <canvas baseChart class="chart"
            [data]="marksData"
            type="line"></canvas>
        </div>
	  </mat-card-content>
	  <mat-card-actions>
        <div class="dark-theme">
            <button mat-raised-button color="primary" (click)="loadMarksData()">{{'load'|translate}}</button>
        </div>
      </mat-card-actions>
	</mat-card>
</div>
<div class="mat-elevation-z8">
	<mat-card class="example-card">
	  <mat-card-header>
	    <mat-card-title>{{'marks-by-question'|translate}}</mat-card-title>
        <mat-card-subtitle>{{'marks-by-question-description'|translate}}</mat-card-subtitle>
	  </mat-card-header>
	  <mat-card-content>
        <div class="bar-chart" *ngIf="showQuestionTypes">
            <canvas baseChart class="chart"
            [data]="questionTypesData"
            type="bar"></canvas>
        </div>
	  </mat-card-content>
	  <mat-card-actions>
        <div class="dark-theme">
            <button mat-raised-button color="primary" (click)="loadQuestionTypes()">{{'load'|translate}}</button>
        </div>
      </mat-card-actions>
	</mat-card>
</div>
<div class="mat-elevation-z8">
	<mat-card class="example-card">
	  <mat-card-header>
	    <mat-card-title>{{'usage-overview'|translate}}</mat-card-title>
        <mat-card-subtitle>{{'usage-overview-description'|translate}}</mat-card-subtitle>
	  </mat-card-header>
	  <mat-card-content>
        <div class="pie-chart" *ngIf="showUsageData">
            <canvas baseChart class="chart"
            [data]="usageData"
            type="pie"></canvas>
        </div>
	  </mat-card-content>
	  <mat-card-actions>
        <div class="dark-theme">
            <button mat-raised-button color="primary" (click)="loadUsageData()">{{'load'|translate}}</button>
        </div>
      </mat-card-actions>
	</mat-card>
</div>