import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

const jsDemoImagesTransform = document.createElement('script');
jsDemoImagesTransform.type = 'text/javascript';
jsDemoImagesTransform.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
document.head.appendChild(jsDemoImagesTransform);

@Component({
	selector: 'app-student-lesson',
	templateUrl: './student-lesson.component.html',
	styleUrls: ['./student-lesson.component.css']
})
export class StudentLessonComponent implements OnInit {

	lesson: any = [];
	uuid: string | null = null;
	apiLoaded: boolean = false;

	public editorOptions: Object = {
		base_url: '/tinymce', // Root for resources
		suffix: '.min',        // Suffix to use when loading resources
		external_plugins: {
			'tiny_mce_wiris' : `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`
		},
		htmlAllowedTags:  ['.*'],
		htmlAllowedAttrs: ['.*'],
		extended_valid_elements: '*[.*]',
		draggable_modal: true,
		plugins: ['autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'table', 'visualblocks', 'wordcount'],
		toolbar: '',
		menubar: '',
		statusbar: false
	};

	constructor(private http: HttpClient, private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

	ngOnInit(): void {
		if (!this.apiLoaded) {
			const tag = document.createElement('script');
			tag.src = 'https://www.youtube.com/iframe_api';
			document.body.appendChild(tag);
			this.apiLoaded = true;
		}

		this.uuid = this.route.snapshot.paramMap.get('uuid');
		this.http.get(`${environment.apiURL}/students/lessons/${this.uuid}`)
			.subscribe((result: any) => {
				this.lesson = result['data'];
			});
	}

	getVideoUrl(videoId: string){
		return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube-nocookie.com/embed/${videoId}`);
	}

	toggleLike(){
		this.http.patch(`${environment.apiURL}/students/lessons/${this.uuid}/like`, {}).subscribe((response: any)=>{
			this.lesson.view = response.data;
		});
	}

}
