<mat-drawer-container class="sidenav-container" autosize #scrollMe>
    <mat-drawer #drawer class="sidenav" mode="over">
        <mat-spinner *ngIf="isLoadingContacts" [diameter]="50"></mat-spinner>
        <h3>{{'conversations'|translate}}</h3>
        <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let conversation of conversations" class="contact"
                (click)="loadConversation(conversation.username); drawer.toggle();">
                <span matListItemTitle>{{ conversation.username }}</span>
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
        <h3>{{'contacts'|translate}}</h3>
        <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let contact of following" class="contact"
                (click)="loadConversation(contact.username); drawer.toggle();">
                <span matListItemTitle>{{ contact.username }}</span>
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
    </mat-drawer>

    <div class="side-menu">
        <button type="button" mat-raised-button (click)="drawer.toggle()">
            <mat-icon>list</mat-icon> <span>{{'contacts'|translate}}</span>
        </button>
    </div>

    <mat-spinner *ngIf="isLoadingChat" [diameter]="50"></mat-spinner>
    <div class="chat" *ngIf="conversationTitle!=''">
        <mat-form-field>
            <mat-label>{{'message'|translate}}</mat-label>
            <input type="text" matInput autocomplete="off" #messageInput
                (keydown.enter)="send(messageInput.value);  messageInput.value='';"
                (keydown.esc)="messageInput.value=''">
            <mat-icon matSuffix (click)="send(messageInput.value); messageInput.value='';"
                class="submit">send</mat-icon>
        </mat-form-field>
        <blockquote #chat *ngFor="let message of messages; let l = last"
            [ngClass]="{'from-me': message.sent_from_me==1, 'to-me': message.sent_from_me==0}">
            <p>{{ message.message }}</p>
            <cite>
                {{ message.sent_from_me==1 ? 'You' : '@'+conversationTitle }}, {{message.created_at | date }}
            </cite>
        </blockquote>
    </div>

</mat-drawer-container>