<div class="header">
    <h1>Ddolphin Educação</h1>
    <h2>Qualquer um sabe algo que possa ensinar. Todos têm algo para aprender.</h2>
</div>
<div class="body">
    <h2>O que é Ddolphin?</h2>
    <p>Ddolphin é uma plataforma simples, mas muito poderosa, que permite aos usuários compartilhar seus conhecimentos com outros usuários
        em todo o mundo.</p>
    <p>
        <span>Para aqueles que ensinam, eles podem criar lições facilmente com um editor de texto intuitivo no qual podem incorporar
            imagens e vídeos. </span>
        <span>Além disso, os professores podem criar testes intuitivos para que os alunos possam praticar o que aprenderam. Esses
            testes podem ficar online ou podem ser impressos se necessário. </span>
        <span>O construtor de testes permite que os professores selecionem diferentes tipos de perguntas e, dependendo do tipo escolhido, o
            sistema pode avaliar automaticamente os testes. Claro, os professores sempre podem alterar a nota se quiserem ou precisarem.</span>
    </p>
    <p>
        <span>Os alunos recebem constantemente os testes e lições mais recentes das pessoas que seguem, para que não fiquem de fora. </span>
        <span>Dependendo das configurações definidas pelos professores, os alunos podem revisar imediatamente
            testes e obter suas notas instantaneamente.</span>
    </p>
    <p>
        Relatórios intuitivos ajudam os professores a entender o desempenho de seus alunos e também os alunos a ver quão
        bem eles estão se saindo.
    </p>
    <h2>É grátis?</h2>
    <p>Sim! Não há nenhum custo para professores ou alunos. Na verdade, ambos são tratados igualmente e o mesmo usuário pode
        ensinar e aprender com a mesma conta.</p>
    <p>Além de ser gratuito, <b>nunca</b> compartilhamos, vendemos ou fazemos qualquer outro esquema duvidoso que as grandes empresas de tecnologia fazem
        com suas informações. 😉</p>
    <h2>Ainda está em desenvolvimento?</h2>
    <p>Sim, estamos sempre melhorando e adicionando mais recursos à plataforma. Se você tiver sugestões de novos recursos ou quiser relatar um bug, sinta-se à vontade para entrar em contato
        com nosso time em <a href="mailto:contact@ddolphin.com">contact@ddolphin.com</a></p>
    <p>Mas isso não significa que o Ddolphin não seja seguro para uso: você pode usá-lo, pois tudo o que foi publicado até agora foi
        devidamente testado e está funcional.</p>
    <p>Desenvolvimento posterior introduzirá novas ferramentas que tornarão o Ddolphin ainda mais poderoso!</p>
    <p>
        <span>Após o lançamento da versão inicial, o desenvolvimento será contínuo. O que significa que novos recursos
            e bugs sempre serão adicionados para garantir a melhor experiência do usuário.</span>
        <span>Se você tiver sugestões ou relatórios, não hesite em nos contatar com sua solicitação!</span>
    </p>
    <h2>Posso obtê-lo no meu idioma?</h2>
    <p>Ainda não. Entendemos que o inglês é a língua predominantemente utilizada pela comunidade científica e por isso
        foi escolhido como o primeiro idioma para Ddolphin.</p>
    <p>O idioma Português foi aidionado ao fim de 2022 e até o fim do primeiro semestre de 2022 o Ddolphin estará traduzido para o Espanhol e então levaremos as sugestões dos usuários para
        escolha dos próximos idiomas.</p>
    <p>Muitos navegadores modernos permitem a tradução instantânea de páginas que são totalmente compatíveis com o Ddolphin.</p>
    <h2>Existe um App para iOS?</h2>
    <p>Não e nós não planejamos em desenvolver um. Basicamente nós somos uma plataforma sem fins lucrativos que foca em distribuir educação e mesmo assim teríamos que pagar US$299 para a Apple nos deixar publicar um app na App Store.</p>
    <p>Sentimos muito pelos nossos usuários Apple mas você vai ter que usar a versão web pelo seu navegador.</p>
    <p>Você pode adicionar o Ddolphin a sua home para que você tenha um link direto para nossa plataforma.  😃</p>
    <h2>Quais são seus T&Cs?</h2>
    <p>Visite nossa página de <a href="/policies/pt" target="_blank">políticas</a> para saber mais sobre os T&C e as
        políticas de cookies.</p>
</div>
<footer>
    <div>
        <img src="/assets/images/logo/icon.svg" height="36px">
        Ddolphin© versão atual {{ version }}
    </div>
    <span class="footer-right">
        <a href="/policies/pt">T&C e Políticas de Privacidade</a>
    </span>
</footer>