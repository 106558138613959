import { MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements OnInit {

  title: string = 'Confirmation!';
  message: string = 'Are you sure?';

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogModel) {
	    this.title = data.title;
	    this.message = data.message;
  }

  ngOnInit(): void {
  }

  onClose(confirm: boolean){
  	return this.dialogRef.close(confirm);
  }

}

export class ConfirmationDialogModel {

  constructor(public title: string, public message: string) {
  }
}

