<mat-form-field class="db-chip-list">
  <mat-chip-grid #chipList>
    <mat-chip
      *ngFor="let option of selectedOptions"
      [removable]="removable"
      (removed)="remove(option)">
      {{option.label}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      [placeholder]="placeholder | translate"
      #chipInput
      [formControl]="searchCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      (input)="search()">
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
      {{option.label}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>