<div class="header">
    <h1>Ddolphin Educação</h1>
    <h2>Qualquer um sabe algo que possa ensinar. Todos têm algo para aprender.</h2>
</div>
<div class="body">
    <h1>Termos de Uso e Política de Privacidade</h1>
    <p>Última atualização: 20 de dezembro de 2022</p>
    <p>Esta Política de Privacidade descreve Nossas políticas e procedimentos sobre a coleta, uso e divulgação de Suas
        informações
        quando você usa o Serviço é informado sobre Seus direitos de privacidade e como a lei protege você.</p>
    <p>Usamos Seus dados pessoais para fornecer e melhorar o Serviço. Ao usar o Serviço, Você concorda com a coleta
        e
        uso de informações de acordo com esta Política de Privacidade.</p>
    <h2>Interpretação e Definições</h2>
    <h3>Interpretação</h3>
    <p>As palavras cuja letra inicial é maiúscula têm significados definidos nas seguintes condições. o
        as seguintes definições terão o mesmo significado independentemente de aparecerem no singular ou no plural.
    </p>
    <h3>Definições</h3>
    <p>Para os fins desta Política de Privacidade:</p>
    <p><b>Conta</b> significa uma conta exclusiva criada para Você acessar nosso Serviço ou partes de nosso Serviço.</p>
    <p><b>Empresa</b> (referida como "a empresa", "nós", "nós" ou "nosso" neste contrato) refere-se à Ddolphin.
    </p>
    <p><b>Cookies</b> são pequenos arquivos que são colocados em seu computador, dispositivo móvel ou qualquer outro
        dispositivo por um site,
        contendo os detalhes do seu histórico de navegação nesse site entre seus muitos usos.</p>
    <p><b>País</b> refere-se a: República da Irlanda.</p>
    <p><b>Dispositivo</b> significa qualquer dispositivo que possa acessar o Serviço, como um computador, celular ou
        tablet digital.
    </p>
    <p><b>Dados Pessoais</b> são quaisquer informações relacionadas a um indivíduo identificado ou identificável.</p>
    <p><b>Serviço</b> refere-se ao site.</p>
    <p><b>Provedor de Serviços</b> significa qualquer pessoa física ou jurídica que processa os dados em nome da
        Empresa. Isto
        refere-se
        a empresas terceirizadas ou indivíduos empregados pela Empresa para facilitar o Serviço, para fornecer o
        Serviço ativado
        nome da Empresa, para realizar serviços relacionados ao Serviço ou para auxiliar a Empresa na análise de como o
        O serviço é usado.</p>
    <p><b>Dados de uso</b> refere-se aos dados coletados automaticamente, gerados pelo uso do Serviço ou do
        A própria infraestrutura do serviço (por exemplo, a duração de uma visita à página).</p>
    <p><b>Website</b> refere-se a Ddolphin, acessível em <a href="https://ddolphin.com"
            target="_blank">https://ddolphin.com</a></p>
    <p><b>Você</b> significa o indivíduo acessando ou usando o Serviço, ou a empresa, ou outra pessoa jurídica em nome
        de
        qual tal indivíduo está acessando ou usando o Serviço, conforme aplicável.</p>
    <h2>Termos de Uso</h2>
    <p>Você tem o direito de desempenhar o papel de professor ou aluno em nosso site.</p>
    <p>Você criará apenas uma conta para si mesmo.</p>
    <p>Todo o material postado por Você é de sua propriedade ou será devidamente referenciado.</p>
    <p>Você não compartilhará informações pessoais, suas ou de outras pessoas ou empresas.</p>
    <p>A liberdade de expressão é respeitada, mas o discurso de ódio não será tolerado.</p>
    <p>Este contrato destina-se a ser usado de boa fé por ambas as partes e nenhuma delas explorará áreas cinzentas que
        pode ocorrer.</p>
    <h2>Coleta e uso de seus dados pessoais</h2>
    <h3>Tipos de dados coletados</h3>
    <h3>Dados pessoais</h3>
    <p>Ao usar nosso serviço, podemos solicitar que você nos forneça certas informações de identificação pessoal que
        podem
        ser
        usado para entrar em contato ou identificar Você. As informações de identificação pessoal podem incluir, mas não
        estão limitadas a:</p>
    <ul>
        <li>Endereço de e-mail</li>
        <li>Nome e sobrenome</li>
        <li>Número de telefone</li>
        <li>Dados de uso</li>
    </ul>
    <h3>Dados de uso</h3>
    <p>Os dados de uso são coletados automaticamente ao usar o serviço.</p>
    <p>Os dados de uso podem incluir informações como endereço de protocolo de Internet do seu dispositivo (por exemplo,
        endereço IP), navegador
        modelo,
        versão do navegador, as páginas do nosso serviço que você visita, a hora e a data da sua visita, o tempo gasto
        em
        Essa
        páginas, identificadores exclusivos de dispositivos e outros dados de diagnóstico.</p>
    <p>Quando você acessa o Serviço por ou através de um dispositivo móvel, podemos coletar certas informações
        automaticamente,
        incluindo, entre outros, o tipo de dispositivo móvel que você usa, a ID exclusiva do seu dispositivo móvel, o
        endereço IP
        de
        Seu dispositivo móvel, seu sistema operacional móvel, o tipo de navegador de Internet móvel que você usa,
        dispositivo exclusivo
        identificadores e outros dados de diagnóstico.</p>
    <p>Também podemos coletar informações que seu navegador envia sempre que você visita nosso serviço ou quando acessa
        o
        Serviço por ou através de um dispositivo móvel.</p>
    <h3>Tecnologias de rastreamento e cookies</h3>
    Usamos cookies e tecnologias de rastreamento semelhantes para rastrear a atividade em nosso serviço e armazenar
    determinadas informações.
    As tecnologias de rastreamento usadas são beacons, tags e scripts para coletar e rastrear informações e para
    melhorar e
    analise Nosso Serviço. As tecnologias que usamos podem incluir:
    <ul>
        <li><a id="cookies"><b>Cookies ou cookies do navegador</b>. Um cookie é um pequeno arquivo colocado em seu
                dispositivo. Você pode
                instruir o seu
                navegador para recusar todos os cookies ou para indicar quando um cookie está sendo enviado. No entanto,
                se você não aceitar
                Cookies, você pode não conseguir usar algumas partes do nosso serviço. A menos que você tenha ajustado
                seu navegador
                contexto
                para recusar Cookies, nosso Serviço pode usar Cookies.
            </a></li>
        <li><b>Web Beacons</b>. Certas seções de nosso Serviço e nossos e-mails podem conter pequenos arquivos
            eletrônicos conhecidos
            como web beacons (também conhecidos como clear gifs, pixel tags e single-pixel gifs) que permitem à Empresa,
            por exemplo, para contar os usuários que visitaram essas páginas ou abriram um e-mail e para outros sites
            relacionados
            estatísticas (por exemplo, registrar a popularidade de uma determinada seção e verificar o sistema e o
            servidor
            integridade).
        </li>
    </ul>
    Os cookies podem ser Cookies "Persistentes" ou "Sessão". Cookies persistentes permanecem em seu computador pessoal
    ou celular
    dispositivo quando você fica offline, enquanto os cookies de sessão são excluídos assim que você fecha o navegador
    da web.
    Usamos cookies de sessão e cookies persistentes para os fins descritos abaixo:
    <ul>
        <li>
            <b>Cookies necessários/essenciais</b>
            Tipo: Cookies de sessão
            Administrado por: Nós
            Objetivo: Esses cookies são essenciais para fornecer a você os serviços disponíveis no site e para
            permitir que você use alguns de seus recursos. Eles ajudam a autenticar usuários e evitar o uso fraudulento
            de usuários
            contas. Sem esses cookies, os serviços que você solicitou não podem ser fornecidos e usamos apenas
            esses Cookies para fornecer a Você esses serviços.
        </li>
    </ul>
    Para obter mais informações sobre os cookies que usamos e suas escolhas em relação aos cookies, visite nossa
    Política de Cookies ou
    a seção Cookies de nossa Política de Privacidade.
    <h2>Uso de seus dados pessoais</h2>
    A Empresa pode utilizar os Dados Pessoais para as seguintes finalidades:
    <ul>
        <li>
            Para fornecer e manter nosso Serviço, inclusive para monitorar o uso de nosso Serviço.
        </li>
        <li>
            Para gerenciar sua conta: para gerenciar seu registro como usuário do serviço. Os dados pessoais que você
            fornece
            pode dar a Você acesso a diferentes funcionalidades do Serviço que estão disponíveis para Você como um
            usuário registrado
            do utilizador.
        </li>
        <li>
            Para a execução de um contrato: o desenvolvimento, cumprimento e execução do contrato de compra para
            os produtos, itens ou serviços que você comprou ou de qualquer outro contrato conosco por meio do Serviço.
        </li>
        <li>
            Para entrar em contato com você: Para entrar em contato com você por e-mail, telefonemas, SMS ou outras
            formas equivalentes de
            comunicação, como notificações push de um aplicativo móvel sobre atualizações ou informações
            comunicações relacionadas com as funcionalidades, produtos ou serviços contratados, incluindo a segurança
            atualizações, quando necessárias ou razoáveis para sua implementação.
        </li>
        <li>
            Para lhe fornecer notícias, ofertas especiais e informações gerais sobre outros produtos, serviços e eventos
            qual nós
            ofertas semelhantes àquelas que você já comprou ou sobre as quais consultou, a menos que tenha optado por
            não
            para
            receber tais informações.
        </li>
        <li>
            Para gerenciar Suas solicitações: Para atender e gerenciar Suas solicitações para Nós.
        </li>
        <li>
            Para transferências de negócios: podemos usar suas informações para avaliar ou conduzir uma fusão,
            alienação,
            reestruturação,
            reorganização, dissolução ou outra venda ou transferência de alguns ou de todos os nossos ativos, seja como
            uma operação em andamento
            preocupação
            ou
            como parte de falência, liquidação ou procedimento semelhante, em que os Dados Pessoais mantidos por Nós
            sobre nossos
            Serviço
            usuários
            está entre os ativos transferidos.
        </li>
        <li>
            Para outros fins: podemos usar suas informações para outros fins, como análise de dados, identificação de
            uso
            tendências,
            determinar a eficácia de nossas campanhas promocionais e avaliar e melhorar nosso Serviço,
            produtos,
            serviços, marketing e sua experiência.
        </li>
    </ul>
    Podemos compartilhar suas informações pessoais nas seguintes situações:
    <ul>
        <li>
            Com provedores de serviços: podemos compartilhar suas informações pessoais com provedores de serviços para
            monitorar e analisar
            o uso
            do nosso Serviço, para contatá-lo.
        </li>
        <li>
            Para transferências de negócios: podemos compartilhar ou transferir suas informações pessoais em conexão
            com, ou durante
            negociações de qualquer fusão, venda de ativos da Empresa, financiamento ou aquisição de toda ou parte de
            Nossa
            o negócio
            para outra empresa.
        </li>
        <li>
            Com afiliados: podemos compartilhar suas informações com nossos afiliados e, nesse caso, exigiremos que eles
            afiliados
            para honrar esta Política de Privacidade. As afiliadas incluem nossa empresa controladora e quaisquer outras
            subsidiárias,
            risco
            parceiros ou outras empresas que controlamos ou que estão sob controle comum conosco.
        </li>
        <li>
            Com parceiros de negócios: podemos compartilhar suas informações com nossos parceiros de negócios para
            oferecer a você determinados
            produtos,
            serviços ou promoções.
        </li>
        <li>
            Com outros usuários: quando você compartilha informações pessoais ou interage de outra forma nas áreas
            públicas com outros
            usuários,
            tais informações podem ser visualizadas por todos os usuários e podem ser distribuídas publicamente fora.
            Com seu consentimento: podemos divulgar suas informações pessoais para qualquer outra finalidade com seu
            consentimento.
        </li>
    </ul>
    <h2>Retenção de seus dados pessoais</h2>
    <p>A Empresa reterá Seus Dados Pessoais apenas pelo tempo necessário para os fins estabelecidos neste
        Privacidade
        Política. Reteremos e usaremos Seus Dados Pessoais na medida do necessário para cumprir nossas obrigações legais
        (para
        exemplo, se formos obrigados a reter seus dados para cumprir as leis aplicáveis), resolver disputas e fazer
        cumprir
        nosso
        acordos legais e políticas.</p>
    <p>A Empresa também reterá Dados de Uso para fins de análise interna. Os Dados de Uso geralmente são retidos por um
        período de tempo mais curto, exceto quando esses dados são usados para reforçar a segurança ou para melhorar a
        funcionalidade
        de
        Nosso Serviço, ou Somos legalmente obrigados a reter esses dados por períodos de tempo mais longos.</p>
    <h2>Transferência de seus dados pessoais</h2>
    <p>Suas informações, incluindo Dados Pessoais, são processadas nos escritórios operacionais da Empresa e em qualquer
        outro
        lugares
        onde se encontram as partes envolvidas no tratamento. Isso significa que essas informações podem ser
        transferidas para -
        e
        mantido em — computadores localizados fora do seu estado, província, país ou outra jurisdição governamental
        Onde
        as leis de proteção de dados podem diferir daquelas de Sua jurisdição.</p>
    <p>Seu consentimento para esta Política de Privacidade seguido de Seu envio de tais informações representa Sua
        concordância em
        essa transferência.</p>
    <p>A Empresa tomará todas as medidas razoavelmente necessárias para garantir que Seus dados sejam tratados com
        segurança e em
        conformidade
        com esta Política de Privacidade e nenhuma transferência de Seus Dados Pessoais ocorrerá para uma organização ou
        país
        a menos que haja controles adequados em vigor, incluindo a segurança de seus dados e outras informações
        pessoais.
    </p>
    <h2>Excluir seus dados pessoais</h2>
    <p>Você tem o direito de excluir ou solicitar que ajudemos a excluir os Dados Pessoais que coletamos sobre
        Vocês. O processo pode ser iniciado pela sua página de configurações do perfil. A exclusão está além de qualquer tipo de
        recuperação.</p>
    <p>Você pode atualizar, corrigir ou excluir suas informações a qualquer momento, acessando sua conta, se tiver uma,
        e
        visitando a seção de configurações de perfil que permite que você gerencie suas informações pessoais. Você
        também pode entrar em contato
        Nós
        para solicitar acesso, corrigir ou excluir qualquer informação pessoal que você nos forneceu.</p>
    <p>Observe, no entanto, que podemos precisar reter certas informações quando tivermos uma obrigação legal ou
        base
        para fazer isso.</p>
    <h2>Divulgação de seus dados pessoais</h2>
    <h3>Transações comerciais</h3>
    Se a Empresa estiver envolvida em uma fusão, aquisição ou venda de ativos, Seus Dados Pessoais poderão ser
    transferidos. Nós vamos
    forneça um aviso antes que Seus Dados Pessoais sejam transferidos e se tornem sujeitos a uma Política de Privacidade
    diferente.
    <h3>Aplicação da lei</h3>
    Sob certas circunstâncias, a Empresa pode ser obrigada a divulgar Seus Dados Pessoais se for obrigada a fazê-lo por
    lei
    ou em resposta a solicitações válidas de autoridades públicas (por exemplo, um tribunal ou agência governamental).
    <h3>Outros requisitos legais</h3>
    A Empresa pode divulgar Seus Dados Pessoais na crença de boa fé de que tal ação é necessária para:
    <ul>
        <li>
            Cumprir uma obrigação legal
        </li>
        <li>
            Proteger e defender os direitos ou propriedade da Empresa
        </li>
        <li>
            Prevenir ou investigar possíveis irregularidades relacionadas ao Serviço
        </li>
        <li>
            Proteger a segurança pessoal dos Usuários do Serviço ou do público
        </li>
        <li>
            Proteger contra responsabilidade legal
        </li>
    </ul>
    <h3>Segurança de seus dados pessoais</h3>
    A segurança de seus dados pessoais é importante para nós, mas lembre-se de que nenhum método de transmissão pelo
    Internet ou método de armazenamento eletrônico é 100% seguro. Embora nos esforcemos para usar meios comercialmente
    aceitáveis para
    proteger seus dados pessoais, não podemos garantir sua segurança absoluta.
    <h2>Privacidade das crianças</h2>
    <p>Nosso serviço não se dirige a menores de 13 anos. Não coletamos intencionalmente informações de identificação
        pessoal
        informações de qualquer pessoa com menos de 13 anos de idade. Se você for um dos pais ou responsável e estiver
        ciente de que seu filho
        tem
        nos forneceu dados pessoais, entre em contato conosco. Se tomarmos conhecimento de que coletamos Dados Pessoais
        de
        qualquer pessoa com menos de 13 anos sem verificação do consentimento dos pais, tomamos medidas para remover
        essas informações
        a partir de
        Nossos servidores.</p>
    <p>Se precisarmos confiar no consentimento como base legal para processar suas informações e seu país exigir
        consentimento
        de um pai, podemos exigir o consentimento de seu pai antes de coletarmos e usarmos essas informações.</p>
    <h3>Links para outros sites</h3>
    <p>Nosso serviço pode conter links para outros sites que não são operados por nós. Se Você clicar em um link de
        terceiros,
        Vocês
        será direcionado para o site desse terceiro. Aconselhamos vivamente que reveja a Política de Privacidade de cada
        site
        Vocês
        visita.</p>
    <p>Não temos controle e não assumimos nenhuma responsabilidade pelo conteúdo, políticas de privacidade ou práticas
        de terceiros
        sites ou serviços de terceiros.</p>
    <h3>Mudanças nesta Política de Privacidade</h3>
    <p>Podemos atualizar nossa Política de Privacidade de tempos em tempos. Iremos notificá-lo sobre quaisquer
        alterações, publicando a nova Política de Privacidade
        Política nesta página.</p>
    <p>Informaremos você por e-mail e/ou um aviso em destaque em nosso serviço antes que a alteração entre em vigor
        e
        atualize a data da "Última atualização" na parte superior desta Política de Privacidade.</p>
    <p>Você é aconselhado a revisar esta Política de Privacidade periodicamente para quaisquer alterações. Alterações a
        esta Política de Privacidade são
        entram em vigor quando são postados nesta página.</p>
    <h3>Fale Conosco</h3>
    <p>Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco:</p>
    <p>Por e-mail: <a href="mailto:privacy@ddolphin.com">privacy@ddolphin.com</a></p>
</div>
<footer>
    <div>
        <img src="/assets/images/logo/icon.svg" height="36px"> Ddolphin© versão atual {{ version }}
    </div>
    <span class="footer-right">
        <a href="/policies/pt">T&C e políticas de privacidade</a>
    </span>
</footer>