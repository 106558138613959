import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-pt',
  templateUrl: './about-pt.component.html',
  styleUrls: ['./about-pt.component.css']
})
export class AboutPtComponent implements OnInit {

  public version: string|null = environment.appVersion;
  constructor() { }

  ngOnInit(): void {
  }

}
