import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FriendshipService } from '../../services/friendship.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-friend',
  templateUrl: './friend.component.html',
  styleUrls: ['./friend.component.css']
})
export class FriendComponent implements OnInit {

	  userParam: string|null = '';
	  friend: any;
    lessons: any;
    tests: any;
    public is_loading: boolean = true;

  	constructor(private http: HttpClient, private route: ActivatedRoute, private toastr: ToastrService, 
              private friendService: FriendshipService, private router: Router, private translate: TranslateService) { }

  	ngOnInit(): void {
  		this.userParam = this.route.snapshot.paramMap.get('user');

  		this.loadFriend();
  	}

    unfollow(uuid: string){
      this.friendService.confirmUnfollowing()
        .subscribe(dialogResult=>{
          if (dialogResult)
            this.friendService.unfollow(uuid)
              .subscribe(result=>{
                this.friend.is_added = 0;
                this.toastr.success(this.translate.instant('unfollow-success'), `${this.translate.instant('success')}!`);
              });
          else 
            this.toastr.warning(this.translate.instant('action-cancelled'), this.translate.instant('no-action'));
        });
    }

    follow(uuid: string){
      this.friendService.follow(uuid)
        .subscribe(result=>{
          this.friend.is_added = 1;
          this.friend.accepted_at = this.friend.private_account==1 ? null : "1";
          this.toastr.success(this.translate.instant('success-following'), `${this.translate.instant('success')}!`);
        });
    }

    loadFriend(){
      this.http.get(`${environment.apiURL}/friend/${this.userParam}`)
        .subscribe({
          next: (result: any) => {
            this.friend = result['data'];
            this.is_loading = false;
          },
          error: (error: any)=>{
            if (error.status==404)
              this.router.navigate(['/']);
          }
        });
    }

    loadLessons(){
      this.http.get(`${environment.apiURL}/friend/${this.userParam}/lessons`)
        .subscribe((result: any)=>{
          this.lessons = result['data'];
        });
    }

    loadTests(){
      this.http.get(`${environment.apiURL}/friend/${this.userParam}/tests`)
        .subscribe((result: any)=>{
          this.tests = result['data'];
        });
    }

    unblock(uuid: string){
      this.friendService.unblock(uuid).subscribe(result=>{
        this.friend.is_blocked = 0;
        this.toastr.success(this.translate.instant('success-following'), `${this.translate.instant('success')}!`);
      });
    }

    block(uuid: string){
      this.friendService.confirmBlocking()
        .subscribe(dialogResult=>{
          if (dialogResult)
            this.friendService.block(uuid)
              .subscribe(result=>{
                this.friend.is_blocked = 1;
                this.toastr.success(this.translate.instant('success-following'), `${this.translate.instant('success')}!`);
              });
          else 
            this.toastr.warning(this.translate.instant('action-cancelled'), this.translate.instant('no-action'));
        });
    }

    loadData(tab: number){
    switch (tab) {
      case 0:
        this.loadFriend();
        break;
      case 1:
        this.loadLessons();
        break
      case 2:
        this.loadTests();
      default:
        break;
    }
  }
}