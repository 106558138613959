<mat-spinner *ngIf="isLoadingResults" [diameter]="50"></mat-spinner>
<mat-tab-group *ngIf="!isLoadingResults">
  <mat-tab [label]="'profile' | translate">
    <mat-card *ngIf="user">
      <mat-card-header>
        <mat-spinner *ngIf="isUploadingPicture" [diameter]="50" class="spinner-not-centered"></mat-spinner>
        <input #picutreInput class="avatar-input" type="file" (change)="onPictureSelect($event)">
        <div class="avatar" (click)="picutreInput.click()" *ngIf="!isUploadingPicture">
          <img mat-card-avatar class="avatar-picture" [src]="'data:image/jpg;base64,' + user.profile_picture" *ngIf="user.profile_picture!=null">
          <div class="avatar-empty" *ngIf="user.profile_picture==null">{{ user.name.charAt(0) }}{{ user.surname.charAt(0) }}</div>
          <div class="avatar-label">{{'change'|translate}}...</div>
        </div>
        <span>
          <mat-card-title>{{'user-profile'|translate}}</mat-card-title>
          <mat-card-subtitle>{{'user-profile-info'|translate}}</mat-card-subtitle>
        </span>
      </mat-card-header>
      <mat-card-content>
        <form id="form" [formGroup]="form" (ngSubmit)="onSubmitProfile()">
          <mat-form-field>
            <mat-label>{{'email'|translate}}</mat-label>
            <input matInput type="text" formControlName="email" [readonly]="true">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'username'|translate}}</mat-label>
            <input matInput type="text" autocomplete="off" formControlName="username" required="required" (blur)="checkUsernameAvailability()">
            <mat-error *ngIf="form.controls['username'].hasError('alreadyTaken')">{{'username-taken'|translate}}</mat-error>
            <mat-error *ngIf="!form.controls['username'].hasError('alreadyTaken')">
              {{'username-validation'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'name'|translate}}</mat-label>
            <input matInput type="text" autocomplete="off" formControlName="name" required="required">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'surname'|translate}}</mat-label>
            <input matInput type="text" autocomplete="off" formControlName="surname" required="required">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>{{'dob'|translate}}</mat-label>
            <input matInput [matDatepicker]="dpicker" [formControl]="dob" autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="dpicker"></mat-datepicker-toggle>
            <mat-datepicker #dpicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'language'|translate}}</mat-label>
            <mat-select formControlName="language">
              <mat-option value="en">English</mat-option>
              <mat-option value="pt">Português</mat-option>
            </mat-select>
          </mat-form-field>
          <editor
            [init]="options"
            formControlName="about_me"
            >
          </editor>
          <mat-slide-toggle formControlName="private_account" [color]="primary">
            {{'make-account-private'|translate}}
          </mat-slide-toggle>
        </form>
      </mat-card-content>
      <mat-card-actions class="dark-theme">
        <button type="submit" form="form" mat-raised-button color="primary">
          <mat-icon>save</mat-icon> {{'save'|translate}}
        </button>
      </mat-card-actions>
    </mat-card>
  </mat-tab>
  <mat-tab [label]="'password-reset' | translate">
    <mat-card *ngIf="user">
      <mat-card-header>
        <mat-card-title>{{'password-reset'|translate}}</mat-card-title>
        <mat-card-subtitle>{{'create-new-password'|translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <form id="formPwd" [formGroup]="formPwd" (ngSubmit)="onSubmitPassword()" #formDirective="ngForm">
          <mat-form-field>
            <mat-label>{{'current-password'|translate}}</mat-label>
            <input matInput type="password" autocomplete="off" formControlName="old_password" required="required">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'new-password'|translate}}</mat-label>
            <input matInput type="password" autocomplete="off" formControlName="password" required="required">
            <mat-error>{{'password-validation'|translate}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'confirm'|translate}}</mat-label>
            <input matInput type="password" autocomplete="off" formControlName="password_confirmation" required="required">
            <mat-error *ngIf="formPwd.controls['password_confirmation'].hasError('confirmation')">{{'passwords-dont-match'|translate}}</mat-error>
          </mat-form-field>
        </form>
      </mat-card-content>
      <mat-card-actions class="dark-theme">
        <button type="submit" form="formPwd" mat-raised-button color="primary">
          <mat-icon>save</mat-icon> {{'save'|translate}}
        </button>
      </mat-card-actions>
    </mat-card>
  </mat-tab>
  <mat-tab [label]="'privacy' | translate">
    <mat-card *ngIf="user">
      <mat-card-header>
        <mat-card-title>{{'privacy'|translate}}</mat-card-title>
        <mat-card-subtitle>{{'manage-privacy'|translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div>
          <p>{{'download-data-message'|translate}}</p>
          <button mat-raised-button (click)="downloadData()"><mat-icon>download</mat-icon> {{'download'|translate}}</button>
        </div>
        <div>
          <p>{{'deactivate-account-message'|translate}}</p>
          <form id="formDel" [formGroup]="formDel" (ngSubmit)="deactivate()" #formDirective="ngForm">
            <mat-form-field>
              <mat-label>{{'current-password'|translate}}</mat-label>
              <input matInput type="password" autocomplete="off" formControlName="password" required="required">
            </mat-form-field>
            <button type="submit" mat-raised-button color="warn"><mat-icon>cancel</mat-icon> {{'deactivate'|translate}}</button>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-tab>
</mat-tab-group>