<mat-spinner *ngIf="isLoadingResults" [diameter]="50"></mat-spinner>
<mat-card class="mat-elevation-z success-box" *ngIf="!isLoadingResults&&!error">
    <h2>{{'verification.title'|translate}}</h2>
    <p *ngIf="!alreadyActivated">{{'verification.info1'|translate}}<a routerLink="/login">{{'verification.info2'|translate}}</a></p>
    <p *ngIf="alreadyActivated">{{'verification.info3'|translate}}<a routerLink="/login">{{'verification.info2'|translate}}</a></p>
</mat-card>
<mat-card class="mat-elevation-z error-box" *ngIf="!isLoadingResults&&error">
    <h2>{{'verification.error-title'|translate}}</h2>
    <p>{{'verification.error1'|translate}}<a
        routerLink="/account-recovery">{{'verification.title'|translate}}</a></p>
</mat-card>