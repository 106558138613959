import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

import { AccountRecoveryComponent } from './pages/account-recovery/account-recovery.component';
import { FriendComponent } from './pages/friend/friend.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SearchComponent } from './pages/search/search.component';
import { SignupComponent } from './pages/signup/signup.component';
import { StudentLessonComponent } from './pages/student-lesson/student-lesson.component';
import { TeacherLessonFormComponent } from './pages/teacher-lesson/teacher-lesson-form.component';
import { TeacherLessonTableComponent } from './pages/teacher-lesson/teacher-lesson-table.component';
import { TeacherTestFormComponent } from './pages/teacher-test/teacher-test-form.component';
import { TeacherTestTableComponent } from './pages/teacher-test/teacher-test-table.component';
import { StudentTestComponent } from './pages/student-test/student-test.component';
import { StudentTestTableComponent } from './pages/student-test/student-test-table.component';
import { StudentTestReviewComponent } from './pages/student-test/student-test-review.component';
import { AboutComponent } from './pages/about/about.component';
import { PoliciesComponent } from './pages/policies/policies.component';
import { TeacherGradingTableComponent } from './pages/teacher-grading/teacher-grading-table.component';
import { TeacherGradingFormComponent } from './pages/teacher-grading/teacher-grading-form.component';
import { TeacherTestPrintComponent } from './pages/teacher-test/teacher-test-print.component';
import { StudentPerformanceComponent } from './pages/student-performance/student-performance.component';
import { TeacherDashboardComponent } from './pages/teacher-dashboard/teacher-dashboard.component';
import { NetworkComponent } from './pages/network/network.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { MessagingComponent } from './pages/messaging/messaging.component';
import { EmailVerificationComponent } from './pages/email-verification/email-verification.component';
import { AboutPtComponent } from './pages/about/about-pt.component';
import { PoliciesPtComponent } from './pages/policies/policies-pt.component';

const routes: Routes = [
	{ path: '', component: HomeComponent, canActivate: [ AuthGuardService ], title: 'Ddolphin Home' },
	{ path: 'login', component: LoginComponent, title: 'Ddolphin Login' },
	{ path: 'signup', component: SignupComponent, title: 'Ddolphin Sing Up' },
	{ path: 'account-recovery', component: AccountRecoveryComponent, title: 'Ddolphin - Password Recovery' },
	{ path: 'password-reset/:token', component: PasswordResetComponent, title: 'Ddolphin - Password Recovery' },
	{ path: 'email-confirmation/:token', component: EmailVerificationComponent, title: 'Ddolphin - Email Confirmation'},

	{ path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService], title: 'Ddolphin' },
	{ path: 'network', component: NetworkComponent, canActivate: [AuthGuardService], title: 'Ddolphin'},
	{ path: 'notifications', component: NotificationComponent, canActivate: [AuthGuardService], title: 'Ddolphin'},
	{ path: 'messages', component: MessagingComponent, canActivate: [AuthGuardService], title: 'Ddolphin'},

	{path: 'search/:search', component: SearchComponent, canActivate: [AuthGuardService], title: 'Ddolphin' },

	{ path: 'teacher/lessons', component: TeacherLessonTableComponent, canActivate: [ AuthGuardService ], title: 'Ddolphin' },
	{ path: 'teacher/lessons/create', component: TeacherLessonFormComponent, canActivate: [AuthGuardService], title: 'Ddolphin' },
	{ path: 'teacher/lessons/:uuid', component: TeacherLessonFormComponent, canActivate: [ AuthGuardService ], title: 'Ddolphin' },
	{ path: 'teacher/tests', component: TeacherTestTableComponent, canActivate: [ AuthGuardService ], title: 'Ddolphin' },
	{ path: 'teacher/tests/create', component: TeacherTestFormComponent, canActivate: [ AuthGuardService ], title: 'Ddolphin' },
	{ path: 'teacher/tests/:uuid', component: TeacherTestFormComponent, canActivate: [ AuthGuardService ], title: 'Ddolphin' },
	{ path: 'teacher/tests/print/:uuid', component: TeacherTestPrintComponent, canActivate: [ AuthGuardService ], title: 'Ddolphin' },
	{ path: 'teacher/grading', component: TeacherGradingTableComponent, canActivate: [AuthGuardService], title: 'Ddolphin' },
	{ path: 'teacher/grading/:uuid', component: TeacherGradingFormComponent, canActivate: [AuthGuardService], title: 'Ddolphin' },
	{ path: 'teacher/dashboard', component: TeacherDashboardComponent, canActivate: [AuthGuardService], title: 'Ddolphin'},

	{ path: 'student/lesson/:uuid', component: StudentLessonComponent, canActivate: [ AuthGuardService ], title: 'Ddolphin' },
	{ path: 'student/test/:uuid', component: StudentTestComponent, canActivate: [ AuthGuardService ], title: 'Ddolphin' },
	{ path: 'student/tests', component: StudentTestTableComponent, canActivate: [ AuthGuardService ], title: 'Ddolphin' },
	{ path: 'student/test/review/:uuid', component: StudentTestReviewComponent, canActivate: [ AuthGuardService ], title: 'Ddolphin' },
	{ path: 'student/performance', component: StudentPerformanceComponent, canActivate: [AuthGuardService], title: 'Ddolphin'},

	{path: 'about', component: AboutComponent, title: 'About Ddolphin'},
	{path: 'about/pt', component: AboutPtComponent, title: 'Sobre Ddolphin'},
	{path: 'policies', component: PoliciesComponent, title: 'Ddolphin - Policies'},
	{path: 'policy', component: PoliciesComponent, title: 'Ddolphin - Policies'},
	{path: 'policies/pt', component: PoliciesPtComponent, title: 'Ddolphin - Políticas'},
	{path: 'policy/pt', component: PoliciesPtComponent, title: 'Ddolphin - Policies'},

	{ path: ':user', component: FriendComponent, canActivate: [AuthGuardService], title: 'Ddolphin' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
