<mat-card class="error-box" *ngIf="error">
  {{'account-recovery.not-found1'|translate}}<a routerLink="/signup">{{'account-recovery.not-found2'|translate}}</a>{{'account-recovery.not-found3'|translate}}
</mat-card>
<mat-card class="mat-elevation-z" *ngIf="!success">
  <mat-card-content>
    <form id="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <h2>{{'account-recovery.title'|translate}}</h2>
      <h4>{{'account-recovery.info'|translate}}</h4>
      <mat-form-field class="full-width-input">
        <mat-label>{{'email'|translate}}</mat-label>
        <input matInput formControlName="email" required autocomplete="off">
        <mat-error>
          {{'email-validation'|translate}}
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <span class="btn dark-theme">
      <button type="submit" form="form" mat-raised-button color="primary">Submit</button>
    </span>
  </mat-card-actions>
</mat-card>
<mat-card class="mat-elevation-z success-box" *ngIf="success">
  <h2>{{'account-recovery.success-title'|translate}}</h2>
  <p>{{'account-recovery.success-info1'|translate}}</p>
  <p>{{'account-recovery.success-info2'|translate}}<a routerLink="/account-recovery" (click)="success=false">{{'account-recovery.success-info3'|translate}}</a></p>
</mat-card>