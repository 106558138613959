<mat-tab-group (selectedIndexChange)="loadData($event)">
  <mat-tab label="People">
    <mat-card class="mat-elevation-z8" *ngIf="!people.length">
      <mat-card-content>
        <mat-spinner *ngIf="is_loading" [diameter]="50"></mat-spinner>
        <span *ngIf="!is_loading">{{'no-search-matches'|translate}}</span>
      </mat-card-content>
    </mat-card>
    <mat-card class="mat-elevation-z8" *ngFor="let person of people; index as i;">
      <mat-card-header>
        <a [routerLink]="['/'+person.username]">
          <div class="avatar"
            [ngClass]="{'avatar-acqua': (person.name+person.surname).length%8==0, 'avatar-beige': (person.name+person.surname).length%8==1, 'avatar-blue': (person.name+person.surname).length%8==2, 'avatar-yellow': (person.name+person.surname).length%8==3, 'avatar-pink': (person.name+person.surname).length%8==4, 'avatar-viole': (person.name+person.surname).length%8==5, 'avatar-orange': (person.name+person.surname).length%8==6, 'avatar-green': (person.name+person.surname).length%8==7}">
            <img mat-card-avatar [src]="'data:image/jpg;base64,' +person.profile_picture"
              *ngIf="person.profile_picture!=null">
            <div class="avatar-empty" *ngIf="person.profile_picture==null">{{ person.name.charAt(0) }}{{
              person.surname.charAt(0) }}</div>
          </div>
          <span class="title">
            <mat-card-title>{{ person.name }} {{ person.surname }}</mat-card-title>
            <mat-card-subtitle>@{{ person.username }}, {{'published'|translate|lowercase}} {{ person.lessons_count }} {{'lessons'|translate|lowercase}} {{'and'|translate}} {{
              person.tests_count }} {{'tests'|translate|lowercase}} </mat-card-subtitle>
          </span>
        </a>
      </mat-card-header>
      <mat-card-content>
        <div class="vivid-theme">
          <button type="button" mat-raised-button color="primary" (click)="follow(i)"
            *ngIf="person.awaiting_approval==null">
            <mat-icon>person_add</mat-icon> {{'follow'|translate}}
          </button>
          <button type="button" mat-raised-button color="accent" (click)="unfollow(i)"
            *ngIf="person.awaiting_approval==1">
            <mat-icon>pending_actions</mat-icon> {{'awaiting-approval'|translate}}
          </button>
        </div>
        <div class="dark-theme">
          <button type="button" mat-raised-button color="primary" (click)="unfollow(i)"
            *ngIf="person.awaiting_approval==0">
            <mat-icon>how_to_reg</mat-icon> {{'following'|translate}}
          </button>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-paginator [length]="total_count" [pageSize]="20" (page)="searchPeople($event.pageIndex+1)"></mat-paginator>
  </mat-tab>
  <mat-tab label="Lessons">
    <mat-card class="mat-elevation-z8" *ngIf="!lessons.length">
      <mat-card-content>
        <mat-spinner *ngIf="is_loading" [diameter]="50"></mat-spinner>
        <span *ngIf="!is_loading">{{'no-search-matches'|translate}}</span>
      </mat-card-content>
    </mat-card>
    <mat-card class="mat-elevation-z8" *ngFor="let lesson of lessons; index as i;">
      <a [routerLink]="['/student/lesson/'+lesson.uuid]">
        <mat-card-header>
          <span class="title title-retract">
            <mat-card-title>{{ lesson.title }}</mat-card-title>
            <mat-card-subtitle>{{'publisehd-by'|translate}} {{ lesson.teacher_name }} {{ lesson.teacher_surname }} {{'at'|translate}} {{
              lesson.created_at | date:'MMM d, y, HH:mm' }}</mat-card-subtitle>
          </span>
        </mat-card-header>
        <mat-card-content [innerHtml]="lesson.lesson.substring(0, 250)+'...'"></mat-card-content>
      </a>
    </mat-card>
    <mat-paginator [length]="total_count" [pageSize]="20" (page)="searchLessons($event.pageIndex+1)"></mat-paginator>
  </mat-tab>
  <mat-tab label="Tests">
    <mat-card class="mat-elevation-z8" *ngIf="!tests.length">
      <mat-card-content>
        <mat-spinner *ngIf="is_loading" [diameter]="50"></mat-spinner>
        <span *ngIf="!is_loading">{{'no-search-matches'|translate}}</span>
      </mat-card-content>
    </mat-card>
    <mat-card class="mat-elevation-z8" *ngFor="let test of tests; index as i;">
      <a [routerLink]="['/student/test/'+test.uuid]">
        <mat-card-header>
          <span class="title title-retract">
            <mat-card-title>{{ test.title }}</mat-card-title>
            <mat-card-subtitle>{{'publisehd-by'|translate}} {{ test.teacher_name }} {{ test.teacher_surname }}</mat-card-subtitle>
          </span>
        </mat-card-header>
        <mat-card-content>
          <span>
            <p>
              <span>{{'allow-reviewing'|translate}}</span>
              <mat-icon class="icon-check" *ngIf="test.allow_review">check</mat-icon>
              <mat-icon class="icon-cancel" *ngIf="!test.allow_review">close</mat-icon>
            </p>
            <p>{{'contains'|translate}} {{ test.questions_count }} {{'questions'|translate|lowercase}}</p>
            <p *ngIf="test.privacy==1"><mat-icon>public</mat-icon> {{'public'|translate}}</p>
            <p *ngIf="test.privacy==2"><mat-icon>verified_user</mat-icon> {{'students-only'|translate}}</p>
          </span>
        </mat-card-content>
      </a>
    </mat-card>
    <mat-paginator [length]="total_count" [pageSize]="20" (page)="searchTests($event.pageIndex+1)"></mat-paginator>
  </mat-tab>
</mat-tab-group>