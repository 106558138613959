<div class="mat-elevation-z8">
	<mat-card class="example-card">
	  <mat-card-header>
	    <mat-card-title>{{'popular-lessons'|translate}}</mat-card-title>
        <mat-card-subtitle>{{'popular-lessons-info'|translate}}</mat-card-subtitle>
	  </mat-card-header>
	  <mat-card-content>
        <table *ngIf="showPopularLessons" mat-table [dataSource]="popularLessonsData">
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> {{'title'|translate}} </th>
                <td mat-cell *matCellDef="let lesson" [style.width]="'50%'"> {{ lesson.title }} </td>
            </ng-container>
            <ng-container matColumnDef="views_count">
                <th mat-header-cell *matHeaderCellDef> {{'views'|translate}} </th>
                <td mat-cell *matCellDef="let lesson" [style.width]="'20%'"> {{ lesson.views_count }} </td>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef> {{'created-at'|translate}} </th>
                <td mat-cell *matCellDef="let lesson" [style.width]="'30%'"> {{ lesson.created_at | date:'MMM d, y, HH:mm' }} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['title', 'views_count', 'created_at']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['title', 'views_count', 'created_at'];"></tr>
        </table>
	  </mat-card-content>
	  <mat-card-actions>
        <div class="dark-theme">
            <button mat-raised-button color="primary" (click)="loadPopularLessons()">{{'load'|translate}}</button>
        </div>
      </mat-card-actions>
	</mat-card>
</div>
<div class="mat-elevation-z8">
	<mat-card class="example-card">
	  <mat-card-header>
	    <mat-card-title>{{'liked-lessons'|translate}}</mat-card-title>
        <mat-card-subtitle>{{'liked-lessons-info'|translate}}</mat-card-subtitle>
	  </mat-card-header>
	  <mat-card-content>
        <table *ngIf="showLikedLessons" mat-table [dataSource]="likedLessonsData">
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> {{'title'|translate}} </th>
                <td mat-cell *matCellDef="let lesson" [style.width]="'50%'"> {{ lesson.title }} </td>
            </ng-container>
            <ng-container matColumnDef="likes_count">
                <th mat-header-cell *matHeaderCellDef> {{'likes'|translate}} </th>
                <td mat-cell *matCellDef="let lesson" [style.width]="'20%'"> {{ lesson.likes_count }} </td>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef> {{'created-at'|translate}} </th>
                <td mat-cell *matCellDef="let lesson" [style.width]="'30%'"> {{ lesson.created_at | date:'MMM d, y, HH:mm' }} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['title', 'likes_count', 'created_at']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['title', 'likes_count', 'created_at'];"></tr>
        </table>
	  </mat-card-content>
	  <mat-card-actions>
        <div class="dark-theme">
            <button mat-raised-button color="primary" (click)="loadLikedLessons()">{{'load'|translate}}</button>
        </div>
      </mat-card-actions>
	</mat-card>
</div>
<div class="mat-elevation-z8">
	<mat-card class="example-card">
	  <mat-card-header>
	    <mat-card-title>{{'grade-tests'|translate}}</mat-card-title>
        <mat-card-subtitle>{{'graded-tests-info'|translate}}</mat-card-subtitle>
	  </mat-card-header>
	  <mat-card-content>
        <div class="bar-chart" *ngIf="showGradedTests">
            <canvas baseChart class="chart"
            [data]="gradedTestsData"
            [options]="gradedTestsOptions"
            type="bar"></canvas>
        </div>
	  </mat-card-content>
	  <mat-card-actions>
        <div class="dark-theme">
            <button mat-raised-button color="primary" (click)="loadGradedTests()">{{'load'|translate}}</button>
        </div>
      </mat-card-actions>
	</mat-card>
</div>