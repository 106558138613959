<mat-card class="error-box" *ngIf="error!=''">
  <div [ngSwitch]="error">
    <div *ngSwitchCase="'duplicated'">
      {{ 'existing-email.a' | translate }}<a routerLink="/login">{{ 'existing-email.b' | translate }}</a>{{
      'existing-email.c' | translate }}<a routerLink="/account-recovery">{{ 'existing-email.d' | translate }}</a>
    </div>
    <div *ngSwitchCase="'validation'">
      {{ 'signup-page.validation-error' | translate }}
    </div>
    <div *ngSwitchCase="'unknown'">
      {{ 'signup-page.unknown-error' | translate }}
    </div>
  </div>
</mat-card>
<mat-card class="mat-elevation-z" *ngIf="!success">
  <mat-card-content class="signup-card">
    <mat-grid-list [cols]="breakpoint" (window:resize)="onResize($event)" rowHeight="50">
      <mat-grid-tile [rowspan]="breakpoint==1 ? 3 : 13">
        <div class="banner">
          <h1 [ngClass]="{'mg25': breakpoint!=1, 'mg2': breakpoint==1}">Ddolphin</h1>
          <h2>{{ 'slogan1' | translate  }}<br />{{ 'slogan2' | translate }}</h2>
        </div>
      </mat-grid-tile>
      <mat-grid-tile rowspan="10">
        <div class="signup-form">
          <form [formGroup]="form" (ngSubmit)="onSubmit()" id="signup_form">
            <img src="assets/images/logo/logo.svg" height="64">
            <h2>{{ 'signup' | translate }}</h2>
            <mat-form-field>
              <mat-label>{{ 'username'| translate}}</mat-label>
              <input matInput type="text" autocomplete="off" formControlName="username" required="required"
                (blur)="checkUsernameAvailability()">
              <mat-error *ngIf="form.controls['username'].hasError('alreadyTaken')">{{ 'username-taken' | translate }}
              </mat-error>
              <mat-error *ngIf="!form.controls['username'].hasError('alreadyTaken')">
                {{ 'username-validation' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'name' | translate }}</mat-label>
              <input matInput type="text" autocomplete="off" formControlName="name" required="required">
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'surname' | translate }}</mat-label>
              <input matInput type="text" autocomplete="off" formControlName="surname" required="required">
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'email' | translate }}</mat-label>
              <input matInput formControlName="email" required="required" autocomplete="off">
              <mat-error>{{ 'email-validation'| translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'password' | translate }}</mat-label>
              <input matInput type="password" formControlName="password" required="required" autocomplete="off">
              <mat-error>{{ 'password-validation' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'confirm' | translate }}</mat-label>
              <input matInput type="password" formControlName="password_confirmation" required="required"
                autocomplete="off">
              <mat-error *ngIf="form.controls['password_confirmation'].hasError('confirmation')">{{ 'passwords-dont-match' | translate }}
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-checkbox class="example-margin" [(ngModel)]="agreed">
          <span class="check-tandc">{{ 'policy-agreement.a'| translate }}<a href="/policies" target="_blank">{{ 'policy-agreement.b'| translate }}</a>.</span>
        </mat-checkbox>
      </mat-grid-tile>
      <mat-grid-tile>
        <div [ngClass]="{'vivid-theme': agreed}">
          <button type="submit" form="signup_form" mat-raised-button color="primary" [disabled]="!agreed">{{ 'signup' | translate }}</button>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <button type="button" class="google-button" mat-raised-button color="primary" [disabled]="!agreed"
          (click)="googleAuth()">
          <mat-icon svgIcon="google" *ngIf="agreed"></mat-icon>
          <mat-icon svgIcon="google_disabled" *ngIf="!agreed"></mat-icon>
          {{ 'google-signup'| translate }}
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
<mat-card class="mat-elevation-z success-box" *ngIf="success">
  <h2>{{ 'signup-confirmation.a' | translate }}</h2>
  <p>{{ 'signup-confirmation.b' | translate }}</p>
  <p>{{ 'signup-confirmation.c' | translate }}<a
      routerLink="/account-recovery">{{ 'signup-confirmation.d' | translate }}</a></p>
</mat-card>