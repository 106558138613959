<mat-card class="mat-elevation-z8 card-lesson">
	<mat-card-header>
		<span class="title title-retract">
			<mat-card-title>{{ lesson?.title }}</mat-card-title>
			<mat-card-subtitle>{{'published-at'|translate}} {{ lesson?.created_at | date:'MMM d, y, h:mm' }}</mat-card-subtitle>
		</span>
	</mat-card-header>
	<mat-card-content>
		<div *ngIf="lesson?.video_url" class="video-container">
			<iframe [src]="getVideoUrl(lesson?.video_url)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</div>
		<editor
            [init]="editorOptions"
			[(ngModel)]="lesson.lesson"
			[disabled]="true"
		>
		</editor>
	</mat-card-content>
	<mat-card-actions>
		<button mat-raised-button (click)="toggleLike()">
			<mat-icon *ngIf="lesson?.view?.liked_at">thumb_up</mat-icon>
			<mat-icon class="material-icons-outlined" *ngIf="!lesson?.view?.liked_at">thumb_up</mat-icon>
		</button>
	</mat-card-actions>
</mat-card>
<mat-card class="mat-elevation-z8 card-lesson" *ngIf="lesson?.tests?.length">
	<mat-card-header>
		<mat-card-title>{{'lessons-tests'|translate}}</mat-card-title>
		<mat-card-subtitle>{{'lessons-tests-message'|translate}}</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<div *ngFor="let test of lesson?.tests">
			<h3>
				<a [routerLink]="'/student/test/'+test.uuid" *ngIf="test.privacy==1 || lesson?.are_friends">{{test.title}}</a>
				<span *ngIf="test.privacy==2 && !lesson?.are_friends">{{test.title}}</span>
			</h3>
		</div>
	</mat-card-content>
</mat-card>