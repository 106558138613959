import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { BehaviorSubject } from 'rxjs';
import { Capacitor } from '@capacitor/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PushAndroidService {

  currentMessage = new BehaviorSubject<any>(null);
  private token: string = '';

  constructor(private http: HttpClient) {
    if (Capacitor.getPlatform()==='android') {
      PushNotifications.addListener('registration', (token: Token) => {
        this.token = token.value;
        this.postToken();
      });

      PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
      });
    }
  }

  getToken(){
    return this.token;
  }

  postToken(){
    this.http.post(`${environment.apiURL}/fcm-token`, {fcm_token: this.token}).subscribe((response:any)=>{});
  }

  requestPermission() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive == 'granted')
        PushNotifications.register();
    }).catch(err => { console.log(err) });
  }

  receiveMessage() {
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        this.currentMessage.next(notification);
      },
    );
  }
}
