<mat-spinner *ngIf="isLoadingResults" [diameter]="50"></mat-spinner>
<div class="mat-elevation-z8">
	<mat-card class="example-card no-border-on-print no-margin">
	  <mat-card-header>
	    <mat-card-title>{{ test?.title }}</mat-card-title>
	    <mat-card-subtitle>
	    	{{'teacher'|translate}}: {{ test?.teacher.name }} {{ test?.teacher.surname }}
	    </mat-card-subtitle>
	  </mat-card-header>
      <mat-card-content class="print-only">
        {{'student-name'|translate}}:
      </mat-card-content>
	</mat-card>
</div>
<div class="mat-elevation-z8">
	<div class="question" *ngFor="let question of questions; index as i">
	    <h3>
			{{ i+1 }}) {{ question.question }}
        </h3>
        <div *ngIf="question.type==1">
            <p>{{'answer'|translate}}:</p>
            <p *ngFor="let i of [].constructor(29)">
                <mat-divider></mat-divider>
            </p>
        </div>
        <div *ngIf="question.type>1 && question.type<5">
            <p>{{'answer'|translate}}:</p>
            <mat-divider></mat-divider>
        </div>
		<div *ngIf="question.type==5">
			<p *ngFor="let option of question.options">
                <mat-icon class="align-middle">radio_button_unchecked</mat-icon>
				{{ option.answer }}
            </p>
		</div>
        <div *ngIf="question.type==6">
			<p *ngFor="let option of question.options">
                <mat-icon class="align-middle">check_box_outline_blank</mat-icon>
				{{ option.answer }}
            </p>
		</div>
	</div>
</div>
<mat-card-footer class="print-only">Generated by Ddolphin, https://ddolphin.com/{{test?.teacher.username}}</mat-card-footer>