import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SignupValidator } from 'src/app/validators/signup.validators';

import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

	form = new UntypedFormGroup({
			password: new UntypedFormControl('', [Validators.required, SignupValidator.passwordStrengthValidator]),
			password_confirmation: new UntypedFormControl('', Validators.required)
		}, {
    		validators: SignupValidator.passwordsMatch()
  		});

	success: boolean = false;

  	constructor(private route: ActivatedRoute, private http: HttpClient, private toastr: ToastrService, private auth: AuthService,
				private translate: TranslateService) { }

  	ngOnInit(): void {
  		//making sure user isn't logged in
  		this.auth.logout(false);
  	}

  	onSubmit(){
  		if (!this.form.valid)
  			return;
  		const token = this.route.snapshot.paramMap.get('token');
  		this.http.patch(`${environment.apiURL}/password-reset/${token}`, this.form.getRawValue())
  			.subscribe(result=>{
  				this.toastr.success(this.translate.instant('password-changed'), `${this.translate.instant('success')}!`);
  				this.success = true;
  				this.form.reset();
  			});
  	}

  	checkPasswordsMatch(){
    return (f: UntypedFormGroup) => {
      const password = f.controls['password'];
      const confirmation = f.controls['password_confirmation'];

      if (confirmation.errors && !confirmation.errors.confirmation)
        return;

      confirmation.setErrors(password.value===confirmation.value ? null : {confirmation: true});

      return null;
    }
  }

  hasConfirmationError(){
    if (this.form.controls.password_confirmation.errors==null)
      return false;

    return this.form.controls.password_confirmation.errors.confirmation;
  }


}
