import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.css']
})
export class MessagingComponent implements OnInit {

  public showFiller: boolean = false;
  public conversations = [];
  public following = [];
  public messages = [];
  public conversationTitle: string = '';
  public isLoadingContacts: boolean = true;
  public isLoadingChat: boolean = false;

  @ViewChildren('wholeChat') chat!: QueryList < any >;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get(`${environment.apiURL}/messages`).subscribe((response:any)=>{
      this.conversations = response.data.conversations;
      this.following = response.data.following;
      this.isLoadingContacts = false;
    });
  }

  loadConversation(username:string){
    this.isLoadingChat = true;
    this.http.get(`${environment.apiURL}/messages/${username}`).subscribe((response:any)=>{
      this.messages = response.data;
      this.conversationTitle = username;
      this.isLoadingChat = false;
    });
  }

  send(message: string){
    this.isLoadingChat = true;
    this.http.post(`${environment.apiURL}/messages`, {message: message, to_username: this.conversationTitle}).subscribe((response:any)=>{
      this.messages = response.data;
      this.isLoadingChat = false;
    });
  }

}
