<mat-spinner *ngIf="isLoadingResults" [diameter]="50"></mat-spinner>
<div class="mat-elevation-z8" *ngIf="!isLoadingResults">
    <form id="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="mat-h1">
            <span >{{ options['title'] | translate }}</span>
        </div>
      	<div *ngFor="let element of items">
      		<div [ngSwitch]="element.type">
    			<div *ngSwitchCase="'input'" class="form-field">
    				<mat-form-field class="full-width-input">
                        <mat-label>{{ element.label | translate }}</mat-label>
    					<input matInput [placeholder]="element.placeholder|translate" [formControlName]="element.name" [required]="element.required" [autocomplete]="element.autocomplete">
    					<mat-error>
    						{{'please-provide-a-valid'|translate}} {{ element.label }}
    					</mat-error>
    				</mat-form-field>
    			</div>
                <div *ngSwitchCase="'number'" class="form-field">
                    <mat-form-field class="full-width-input">
                        <mat-label>{{ element.label | translate }}</mat-label>
                        <input matInput [placeholder]="element.placeholder|translate" [formControlName]="element.name" [required]="element.required" [autocomplete]="element.autocomplete" type="number" [min]="element.min" [max]="element.max">
                        <mat-error>
                            {{'please-provide-a-valid'|translate}} {{ element.label }}
                        </mat-error>
                    </mat-form-field>
                </div>
    			<div *ngSwitchCase="'select'" class="form-field">
    				<mat-form-field appearance="fill">
    					<mat-label>{{ element.label|translate }}</mat-label>
    					<mat-select [formControlName]="element.name">
    						<mat-option *ngFor="let option of element.options" [value]="option.value">{{ option.text | translate}}</mat-option>
			      	</mat-select>
			      </mat-form-field>
    			</div>
    			<div *ngSwitchCase="'text-area'" class="form-field">
    				<mat-form-field class="full-width-input">
    					<textarea matInput [placeholder]="element.placeholder|translate" [formControlName]="element.name" [required]="element.required" [rows]="element.rows"></textarea>
    					<mat-hint align="start"><strong>{{ element.hint|translate }}</strong> </mat-hint>
    					<mat-error>
    						{{'please-provide-a-valid'|translate}} {{ element.label }}
    					</mat-error>
    				</mat-form-field>
    			</div>
                <div *ngSwitchCase="'quill'" class="form-field">
                    <editor
                        [init]="editorOptions"
                        [formControlName]="element.name"
                    ></editor>
                    <mat-hint align="start"><strong>{{ element.hint|translate }}</strong> </mat-hint>
                </div>
                <div *ngSwitchCase="'chip'">
                    <div *ngIf="paramValue">
                        <app-chips  [placeholder]="element.placehoder"
                                    [label]="element.label"
                                    [getUrl]="element.getUrl+'/'+paramValue"
                                    [postUrl]="element.postUrl+'/'+paramValue"
                                    [attachMessage]="element.attachMessage"
                                    [detachMessage]="element.detachMessage"
                        >  
                        </app-chips>
                    </div>
                </div>
    		</div>
    	</div>
    </form>
    <div>
        <button type="button" mat-raised-button color="accent" [routerLink]="options['returnLink']"><mat-icon>settings_backup_restore</mat-icon> {{'return'|translate}}</button>
        <button type="button" mat-raised-button *ngFor="let btn of options['extraButtons']" (click)="customBtnClick(btn.link)" [color]="btn.color">
            <mat-icon>{{ btn.icon }}</mat-icon> {{ btn.caption|translate }}
        </button>
        <button type="button" mat-raised-button color="warn" (click)="confirmAndRemove()" *ngIf="paramValue"><mat-icon>delete_forever</mat-icon> {{'delete'|translate}}</button>        
        <span class="dark-theme">
            <button type="submit" form="form" mat-raised-button color="primary" *ngIf="!isWaitingResults">
                    <mat-icon>{{ !paramValue&&options['saveButtonIcon'] ? options['saveButtonIcon'] : 'save' }}</mat-icon> {{ !paramValue&&options['saveButtonCaption'] ? options['saveButtonCaption'] : 'Save' | translate}}
            </button>
        </span>
    </div>
</div>