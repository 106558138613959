<mat-toolbar color="primary" class="expanded-toolbar mat-elevation-z4">
  <span>
    <a routerLink="/"><img src="assets/images/logo/logo.svg" height="36" width="118" alt=""></a>
  </span>
  <div class="toolbar-search" *ngIf="isAuthenticated()">
    <input #globalSearch [placeholder]="'search-box' | translate" autocomplete="off"
      (keydown.enter)="search(globalSearch.value);globalSearch.value='';" (keydown.esc)="globalSearch.value=''">
    <mat-icon (click)="search(globalSearch.value);globalSearch.value='';">search</mat-icon>
  </div>
  <div *ngIf="!isAuthenticated()">
    <select #selectLang matNativeControl (change)="changeLanguage(selectLang.value)" class="language-select">
      <option value="en" [selected]="lang=='en'">🇬🇧 English</option>
      <option value="pt" [selected]="lang=='pt'">🇧🇷 Português</option>
    </select>
  </div>
  <span class="fill-space"></span>
  <div class="bar-menu" *ngIf="!isAuthenticated()">
    <a mat-button routerLink="/login">
      <mat-icon>login</mat-icon> Login
    </a>
    <a mat-button routerLink="/signup">
      <mat-icon>app_registration</mat-icon> {{'signup' | translate}}
    </a>
    <a mat-button [routerLink]="'/about/'+(lang=='en' ? '' : lang)">
      <mat-icon>info</mat-icon> {{'about' | translate}}
    </a>
  </div>
  <div class="bar-menu" *ngIf="isAuthenticated()">
    <button mat-button [matMenuTriggerFor]="profileMenu">
      <mat-icon [matBadge]="getNotificationsCount()+getMessagesCount()" matBadgeColor="warn"
        matBadgePosition="before">account_box</mat-icon> {{'hello'|translate}}, {{ getUserName() }}
    </button>
    <button mat-button [matMenuTriggerFor]="teacherMenu">
      <mat-icon>school</mat-icon> {{'teacher'|translate}}
    </button>
    <button mat-button [matMenuTriggerFor]="studentMenu">
      <mat-icon>design_services</mat-icon> {{'student'|translate}}
    </button>
  </div>
  <button mat-button [matMenuTriggerFor]="mainMenu" class="collapsed-menu">
    <mat-icon [matBadge]="getNotificationsCount()+getMessagesCount()" [matBadgeHidden]="!isAuthenticated()"
      matBadgeColor="warn" matBadgePosition="before">menu</mat-icon>
  </button>
</mat-toolbar>

<mat-menu x-position="after" #mainMenu="matMenu" class="mat-elevation-z16">
  <div *ngIf="!isAuthenticated()">
    <a mat-menu-item routerLink="/login"><mat-icon>login</mat-icon> Login</a>
    <a mat-menu-item routerLink="/signup"><mat-icon>app_registration</mat-icon> {{'signup' | translate}}</a>
    <a mat-menu-item [routerLink]="'/about/'+(lang=='en' ? '' : lang)"><mat-icon>info</mat-icon> {{'about' |
      translate}}</a>
  </div>
  <div *ngIf="isAuthenticated()">
    <a mat-menu-item [matMenuTriggerFor]="profileMenu"><mat-icon [matBadge]="getNotificationsCount()+getMessagesCount()"
        matBadgeColor="warn" matBadgePosition="before">account_box</mat-icon> {{'hello'|translate}}, {{ getUserName()
      }}</a>
    <a mat-menu-item [matMenuTriggerFor]="teacherMenu"><mat-icon>school</mat-icon> {{'teacher'|translate}}</a>
    <a mat-menu-item [matMenuTriggerFor]="studentMenu"><mat-icon>design_services</mat-icon> {{'student'|translate}}</a>
  </div>
</mat-menu>

<mat-menu x-position="after" #profileMenu="matMenu" class="mat-elevation-z16">
  <a mat-menu-item routerLink="/messages"><mat-icon class="menuicon" [matBadge]="getMessagesCount()" matBadgeColor="warn"
      matBadgePosition="before">chat</mat-icon> <span class="menuitem">{{'messages'|translate}}</span></a>
  <a mat-menu-item routerLink="/profile"><mat-icon class="menuicon" >manage_accounts</mat-icon> <span class="menuitem">{{'profile'|translate}}</span></a>
  <a mat-menu-item routerLink="/network"><mat-icon class="menuicon">diversity_1</mat-icon> <span class="menuitem">{{'my-network'|translate}}</span></a>
  <a mat-menu-item routerLink="/notifications"><mat-icon class="menuicon" [matBadge]="getNotificationsCount()" matBadgeColor="warn"
      matBadgePosition="before">notifications</mat-icon> <span class="menuitem">{{'notifications'|translate}}</span></a>
  <mat-divider></mat-divider>
  <a mat-menu-item [routerLink]="'/about/'+(lang=='en' ? '' : lang)"><mat-icon class="menuicon">info</mat-icon> <span class="menuitem">{{'about' |
    translate}}</span></a>
  <mat-divider></mat-divider>
  <a mat-menu-item (click)="logout()"><mat-icon class="menuicon">logout</mat-icon> <span class="menuitem">{{'logout'|translate}}</span></a>
</mat-menu>

<mat-menu x-position="after" #teacherMenu="matMenu" class="mat-elevation-z16">
  <a mat-menu-item routerLink="/teacher/lessons"><mat-icon class="menuicon">topic</mat-icon> <span class="menuitem">{{'lessons'|translate}}</span></a>
  <a mat-menu-item routerLink="/teacher/tests"><mat-icon class="menuicon">history_edu</mat-icon> <span class="menuitem">{{'tests'|translate}}</span></a>
  <a mat-menu-item routerLink="/teacher/grading"><mat-icon class="menuicon">rule</mat-icon> <span class="menuitem">{{'grade-tests'|translate}}</span></a>
  <mat-divider></mat-divider>
  <a mat-menu-item routerLink="/teacher/dashboard"><mat-icon class="menuicon">space_dashboard</mat-icon> <span class="menuitem">{{'dashboard'|translate}}</span></a>
</mat-menu>
<mat-menu x-position="after" #studentMenu="matMenu" class="mat-elevation-z16">
  <a mat-menu-item routerLink="/student/tests"><mat-icon class="menuicon">fact_check</mat-icon> <span class="menuitem">{{'completed-tests'|translate}}</span></a>
  <mat-divider></mat-divider>
  <a mat-menu-item routerLink="/student/performance"><mat-icon class="menuicon">insights</mat-icon> <span class="menuitem">{{'performance'|translate}}</span></a>
</mat-menu>

<router-outlet></router-outlet>