import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teacher-test-print',
  templateUrl: './teacher-test-print.component.html',
  styleUrls: ['./teacher-test-print.component.css']
})
export class TeacherTestPrintComponent implements OnInit {

  public test: any;
  public questions: any;
  public isLoadingResults: boolean = false;

  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    let uuid = this.route.snapshot.paramMap.get('uuid');
    this.http.get(`${environment.apiURL}/teachers/tests/${uuid}`).subscribe((response:any)=>{
      this.test = response.data;
    });

    this.http.get(`${environment.apiURL}/teachers/tests/${uuid}`).subscribe((response:any)=>{
      this.test = response.data;
      this.http.get(`${environment.apiURL}/teachers/tests/${uuid}/questions`).subscribe((response: any)=>{
        this.questions = response.data;
        this.isLoadingResults = false;
      });
    });
  }

}
