<app-table 	[displayedColumns]="displayedColumns" 
			[columns]="columns" 
			[pageSize]="25" 
			url="/students/tests/attempts"
			[title]="'completed-tests'|translate"
			sortColumn="created_at"
			sortDirection="desc"
			formLink='/student/test/review/'
			editParam='uuid'
			hideAddButton="true"
			hideSearch="true">
</app-table>