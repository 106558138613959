import { Injectable } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent, ConfirmationDialogModel } from '../confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private dialog: MatDialog, private translate: TranslateService) { }

  confirmDeactivate() {
    const dialogData = new ConfirmationDialogModel(this.translate.instant('confirm-action'), this.translate.instant('confirm-deactivate'));
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: "70%",
      data: dialogData,
      backdropClass: 'backdrop-background',
      hasBackdrop: true
    });
    dialogRef.updatePosition({ top: "10%" });

    return dialogRef.afterClosed();
  }
}
