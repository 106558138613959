import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Capacitor } from '@capacitor/core';

const isAndroid = Capacitor.getPlatform()==='android';

@Injectable()

export class MessagingService{

    currentMessage = new BehaviorSubject<any>(null);
    private token: string|null = '';

    constructor(private angularFireMessaging: AngularFireMessaging, private http: HttpClient){        
    }

    requestPermission(){
        this.angularFireMessaging.requestToken.subscribe({
            next: (token)=>{
                this.http.post(`${environment.apiURL}/fcm-token`, {fcm_token: token}).subscribe((response:any)=>{
                    this.token = token;
                });
            }
        });
    }

    receiveMessage(){
        this.angularFireMessaging.messages.subscribe((payload)=>{
            this.currentMessage.next(payload);
        })
    }

    getToken(){
        return this.token;
    }

}