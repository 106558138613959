import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teacher-grading-form',
  templateUrl: './teacher-grading-form.component.html',
  styleUrls: ['./teacher-grading-form.component.css']
})
export class TeacherGradingFormComponent implements OnInit {
  public questions: any;
  public attempt: any;
  public isLoadingResults: boolean = false;
  public note: string = '';
  private uuid: string|null = '';

  constructor(private http: HttpClient, private route: ActivatedRoute, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.http.get(`${environment.apiURL}/teachers/grading/${this.uuid}`).subscribe((response:any)=>{
      this.attempt = response['data'];
      this.questions = this.attempt.questions;
      this.note = this.attempt.message_from_teacher;
      this.isLoadingResults = false;
    });
  }

  public editGrade(uuid: string){
    let grade = (<HTMLInputElement>document.getElementById(`answer_${uuid}`)).value;
    let data = {grade: grade};
    this.http.patch(`${environment.apiURL}/teachers/grading/${this.uuid}/answers/${uuid}`, data).subscribe((response:any)=>{
      this.attempt.grade = response.data;
      this.toastr.success(this.translate.instant('answer-marks-updated'), `${this.translate.instant('success')}!`);
    });
  }

  public saveNote(){
    let data = {note: this.note};
    this.http.patch(`${environment.apiURL}/teachers/grading/${this.uuid}/note`, data).subscribe((response:any)=>{
      this.toastr.success(this.translate.instant('note-saved'), `${this.translate.instant('success')}!`);
    });
  }
}
